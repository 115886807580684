import React, {memo, useCallback} from "react";
import {useAppDispatch} from "store";
import {setPaymentPage} from "store/payment/slice";
import {setModule} from "store/modules/slice";
import MODULES from "pages/modules";
import {PAYMENT_PAGE} from "pages/payment/consts";
import {Button} from "components/Button";
import {BTN_COLOR} from "components/Button/consts";
import {LOG_TYPES} from "utils/consts";
import {log} from "utils/analytics";

import styles from "./styles.module.scss";

import type {SubscriptionInfo} from "pages/home/Model/ParkingInfo";

interface Props {
  debt?: string;
  subscription?: SubscriptionInfo | null;
}

export const PaymentNotify: React.FC<Props> = memo(({debt, subscription}) => {
  const dispatch = useAppDispatch();

  const handleOpenInfo = useCallback(() => {
    dispatch(setModule(MODULES.PAYMENT));
    dispatch(setPaymentPage(PAYMENT_PAGE.ABOUT));
    log(LOG_TYPES.SUBSCRIPTION_CARD_PRESS, null, {
      SubscriptionID: subscription?.id,
      SubscriptionName: subscription?.title
    });
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>К оплате за валет-парковку</div>
      <div className={styles.value}>{debt}₽</div>
      <Button color={BTN_COLOR.GRAY_SMALL} onClick={handleOpenInfo}>Узнать о тарифе и способах оплаты</Button>
    </div>
  );
});
