import {api} from "./index";

export async function getObject(slug: string) {
  return api.get(`open/property-object/item/${slug}/`);
}

export async function getCategory(id: number | string) {
  return api.get(`open/property-object/product-category/item/${id}/`);
}

export async function getProduct(id: number | string) {
  return api.get(`open/property-object/product/item/${id}/`);
}
