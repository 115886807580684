import {createAsyncThunk} from "@reduxjs/toolkit";
import {checkStatus, initPayment} from "services/payment";
import {logEvent}         from "utils/analytics";
import {LOG_TYPES}        from "utils/consts";

import type {RootState}   from "store";

export const startPaymentProcess = createAsyncThunk(
  'payment/init',
  async (_, {rejectWithValue, getState}) => {
    const model = getState() as RootState;
    const vsid = model?.parking?.parkingInfo?.id;
    const parkingId = model.parking?.parkingInfo?.parking?.id;
    const cardId = model.parking?.parkingInfo?.valet_card_id;

    const host = document.location.origin;
    const successUrl = `${host}/?P=${parkingId}&paymentStatus=true&VCID=${cardId}`;
    const failUrl = `${host}/?P=${parkingId}&paymentStatus=false&VCID=${cardId}`;

    if (!vsid) {
      rejectWithValue('Нет session id!');
    }

    try {
      const res = await initPayment({
        firstName: 'Тест',
        email: model?.payment?.email?.length ? model.payment.email : 'dev@getvalet.ru',
        phone: '+79272133133',
        vsid,
        successUrl,
        failUrl
      });
      if (res) {
        await logEvent(LOG_TYPES.BTN_INIT_PAYMENT, null, {
          ObjectID: model?.parking?.parkingInfo?.parking?.id,
          Status: 'Success'
        });
        setTimeout(() => {
          window.location.href = res.data?.payment_data?.init_url;
        }, 100);
      }
    } catch (e) {
      await logEvent(LOG_TYPES.BTN_INIT_PAYMENT, null, {
        ObjectID: model?.parking?.parkingInfo?.parking?.id,
        Status: 'Error'
      });
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const checkPaymentStatus = createAsyncThunk(
  'payment/checkStatus',
  async (orderId: string, {rejectWithValue}) => {
    if (!orderId) {
      rejectWithValue('Нет order id!');
    }

    try {
      const res = await checkStatus({
        orderId
      });
      return res.data;
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);
