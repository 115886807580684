export const STATUSES = [
  'Автомобиль принят',
  'Парковка автомобиля',
  'Автомобиль припаркован',
  'В процессе подачи',
  'В процессе подачи',
  'В процессе подачи',
  'Машина ожидает',
  'Машина выдана',
  'Машина выдана',
  'Машина выдана'
] as const;

export const SERVICE_STATUSES_TEXT = [
  "Отменён",
  "Ожидайте звонка",
  "Выполняется",
  "Выполняется",
  "Выполняется",
  "Выполняется",
  "Выполняется",
  "Выполняется",
  "Выполняется",
  "Выполняется",
  "Оказана"
] as const;

export const SERVICE_STATUSES = {
  WAITING: 'WAITING',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE'
};

export const SERVICE_TYPE = {
  FUELING: 'Gas',
  WASHING: 'Wash'
};
