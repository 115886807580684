import React  from 'react';

import styles from "./styles.module.scss";

interface BarProps {
  percent: number;
  label: string;
}

export const Bar: React.FC<BarProps> = ({ percent, label }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.bar}>
        <div className={styles.label}>
          {label}
        </div>
        <div className={styles.value} style={{width: percent + '%'}}/>
      </div>
    </div>
  );
};
