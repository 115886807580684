import React, {memo, useEffect} from "react";
import {useSelector}            from "react-redux";
import {LOG_TYPES}              from "utils/consts";
import {log}                    from "utils/analytics";
import {Description}            from "components/Description";
import {TEMPLATE}               from "pages/service/consts";

import styles                   from "./styles.module.scss";

import type {RootState}         from "store";

export const About: React.FC = memo(() => {
  const {parkingInfo} = useSelector((s: RootState) => s.parking);

  useEffect(() => {
    log(LOG_TYPES.SERVICE_PAGE_ABOUT_SERVICES, parkingInfo);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Description template={TEMPLATE} />
    </div>
  );
});
