import React, {memo}                    from "react";

import styles                           from "./styles.module.scss";

import type {PaymentInfo}               from "pages/payment/Model/PaymentInfo";

interface Props {
  paymentInfo?: PaymentInfo | null;
}

export const SubscriptionPaymentCard: React.FC<Props> = memo(({
  paymentInfo
}) => {
  return (
    <>
    <div className={styles.card}>
      <div className={styles.title}>
        Спасибо услуга оплачена
      </div>
      <div className={styles.option}>
        <div className={styles.label}>Заказ №:</div>
        <div className={styles.value}>{paymentInfo?.order?.human_order_id}</div>
      </div>
      <div className={styles.option}>
        <div className={styles.label}>Оплачено:</div>
        <div className={styles.sum}><i/>{paymentInfo?.invoice?.amount} ₽</div>
      </div>
      <div className={styles.description}>
        Спасибо за оплату. В ближайшее время с вами свяжется менеджер.
      </div>
    </div>
    <div className={styles.empty}/>
    </>
  );
});
