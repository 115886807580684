import {getDateByTime}  from "utils/date";
import type {Dayjs}     from "dayjs";

export default function getPercentFromTimes(startTime: Date | Dayjs, endTime: Date | Dayjs, currentTime: Date | Dayjs) {
  const full = getDateByTime(endTime).diff(getDateByTime(startTime)); // 100%
  const current = getDateByTime(currentTime).diff(getDateByTime(startTime)); // текущий значение (процент)
  const percent = current * 100 / full;

  if (percent >= 100) {
    return 100;
  } else if (percent <= 0) {
    return 0;
  } else {
    return percent;
  }
}
