import {memo} from "react";
import Home from "pages/home/views";

function HomePage() {
  return (
    <Home />
  );
}

export default memo(HomePage);
