import React from "react";

import styles from "./styles.module.scss";

const EmptyProduct = () => {
  return (
    <div className={styles.empty}>
      <div className={styles.emptyImage} />
      <div className={styles.emptyDescription} />
      <div className={styles.emptyPrice} />
    </div>
  );
};

export default React.memo(EmptyProduct);
