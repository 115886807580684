import React, {memo, useMemo}           from "react";
import {getDurationTime, getPrefixDate} from "utils/date";

import styles                           from "./styles.module.scss";

import type {Dayjs}                     from "dayjs";
import type {ParkingInfo}               from "pages/home/Model/ParkingInfo";

interface Props {
  parkingInfo: ParkingInfo | null;
  currentTime: Dayjs;
  startedTime?: Dayjs;
}

export const InfoPaymentCard: React.FC<Props> = memo(({
  parkingInfo,
  currentTime,
  startedTime
}) => {

  const duration = useMemo(() => startedTime ? getDurationTime(startedTime, currentTime, true) : null, [startedTime, currentTime]);
  const number = parkingInfo?.car_number || '-';
  const place = parkingInfo?.parking_place || '-';
  const startTime = getPrefixDate(startedTime, currentTime) || '-';
  const valetCard = parkingInfo?.valet_card_id || '-';
  const debt = parkingInfo?.payment_data?.session_cost?.split('.')[0]?.replace('-', '');

  return (
    <div className={styles.card}>
      <div className={styles.debt}>
        <div className={styles.title}>К оплате:</div>
        <div className={styles.sum}>{debt}₽</div>
      </div>
      <div className={styles.info}>
        <div className={styles.option}>
          <div className={styles.title}>Автомобиль:</div>
          <div className={styles.value}>{number}</div>
        </div>
        <div className={styles.option}>
          <div className={styles.title}>Время приёма авто:</div>
          <div className={styles.value}>{startTime}</div>
        </div>
        <div className={styles.option}>
          <div className={styles.title}>Время на парковке:</div>
          <div className={styles.value}>{duration}</div>
        </div>
        <div className={styles.option}>
          <div className={styles.title}>Валет-карта:</div>
          <div className={styles.value}>{valetCard}</div>
        </div>
        <div className={styles.option}>
          <div className={styles.title}>Место:</div>
          <div className={styles.value}>{place}</div>
        </div>
      </div>
    </div>
  );
});
