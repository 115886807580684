import {createSlice}                      from '@reduxjs/toolkit';
import {STATUS}                           from "store/consts";
import MODULES                            from "pages/modules";

interface ModulesState {
  loading: valueof<typeof STATUS>;
  module: valueof<typeof MODULES> | null;
}

const initialState: ModulesState = {
  loading: STATUS.FULFILLED,
  module: null
};

const counterSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setModule(state, {payload}) {
      state.module = payload;
    }
  }
});

export const {
  setModule
} = counterSlice.actions;
export default counterSlice.reducer;
