import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {setOpenedConditions} from "store/market/slice";
import {Icon} from "components/Icon";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const Support = () => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const openConditions = useCallback(() => {
    dispatch(setOpenedConditions(true));
  }, []);

  return (
    <div className={styles.support}>
      <div className={styles.label}>Мы рядом и готовы помочь</div>
      <div className={styles.social}>
        <a href={model?.market?.tgLink} target="_blank" rel="noreferrer"><div><Icon.Telegram /></div></a>
        <a href={model?.market?.whatsappLink} target="_blank" rel="noreferrer"><div><Icon.Whatsapp /></div></a>
      </div>
      <div className={styles.links}>
        <a href={model?.market?.offer} target="_blank" rel="noreferrer">Договор оферты</a>
        <a onClick={openConditions}>Условия пользования сервисом</a>
      </div>
    </div>
  );
};

export default React.memo(Support);
