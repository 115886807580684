import React        from 'react';
import {createRoot} from 'react-dom/client';
import {Provider}   from 'react-redux';
import App          from 'pages';
import {store}      from 'store';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Market from "./pages/market";

const ROUTER = [
  {
    path: "/",
    element: <App />
  },
  {
    path: "/:slug",
    element: <Market />
  }
];

const container = document.getElementById('root');
const root = createRoot(container!);

const router = createBrowserRouter(ROUTER);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
