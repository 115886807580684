import React, {memo, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {log} from "utils/analytics";
import {Button} from "components/Button";
import {LOG_TYPES} from "utils/consts";

import type {RootState} from "store";

import styles from "./styles.module.scss";

const ANSWERS = {
  YES: 'yes',
  NO: 'no'
} as const;

export const Fueling: React.FC = memo(() => {
  const [hasAnswer, setHasAnswer] = useState<boolean>(false);
  const {parkingInfo} = useSelector((s: RootState) => s.parking);

  useEffect(() => {
    log(LOG_TYPES.SERVICE_PAGE_GAS_STATION_SERVICES, parkingInfo);
  }, []);

  const handleAnswer = (val: valueof<typeof ANSWERS>) => {
    if (val === ANSWERS.YES) {
      log(LOG_TYPES.BTN_TRY_GAS_STATION_SERVICE_YES, parkingInfo);
    } else {
      log(LOG_TYPES.BTN_TRY_GAS_STATION_SERVICE_NO, parkingInfo);
    }
    setHasAnswer(true);
  };

  return (
    <div>
      <div className={styles.text}>Спасибо за проявленный интерес! Услуга в процессе подключения.</div>
      {hasAnswer ? (
        <div className={styles.question}>Спасибо за ответ!</div>
      ) : (
        <>
          <div className={styles.question}>Когда услуга появится, хотите ей воспользоваться?</div>
          <div className={styles.answers}>
            <Button onClick={() => handleAnswer(ANSWERS.YES)}>Да, попробую</Button>
            <Button onClick={() => handleAnswer(ANSWERS.NO)}>Нет, спасибо</Button>
          </div>
        </>
      )}
    </div>
  );
});
