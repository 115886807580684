import {memo}   from "react";
import Market  from "pages/market/views";

function MarketModule() {
  return (
    <Market />
  );
}

export default memo(MarketModule);
