import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {setAgreement, setError, setField, setOpenedConditions, setTouched} from "store/market/slice";
import {Field} from "components/Field";
import Checkbox from "components/Checkbox";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const PaymentInfo = () => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const onChangeAgreement = useCallback((val: boolean) => {
    dispatch(setAgreement(val));
    if (!model.touched?.agreement) {
      dispatch(setTouched({name: 'agreement', value: true}));
    }
    if (!model.errors?.agreement && !val) {
      dispatch(setError({name: 'agreement', value: 'Обязательное поле'}));
    }
    if (model.errors?.agreement && val) {
      dispatch(setError({name: 'agreement', value: null}));
    }
  }, [model.errors, model.touched]);

  const onChangeField = useCallback((name: string, value: string) => {
    if (model.errors[name]) {
      dispatch(setError({name, value: null}));
    }
    dispatch(setField({name, value}));
  }, [model.errors]);

  const onBlurField = useCallback((name: string) => {
    if (!model.errors[name] && !model.fields[name]?.length) {
      dispatch(setError({name, value: 'Обязательное поле'}));
    }
  }, [model.errors, model.fields]);

  const openConditions = useCallback(() => {
    dispatch(setOpenedConditions(true));
  }, []);

  return (
    <div className={styles.paymentInfo}>
      <div className={styles.paymentLabel}>Контактные данные (обязательно):</div>
      <div className={styles.paymentDesc}>С вами свяжется оператор сервиса аренды и подтвердит заказ. </div>
      <Field
        name="name"
        inputMode="text"
        placeholder="Ваше имя"
        value={model.fields.name || ''}
        onChange={(v) => onChangeField('name', v)}
        onBlur={() => onBlurField('name')}
        error={model.errors?.name}
      />
      <Field
        name="phone"
        inputMode="tel"
        placeholder="+7 123 456 78 90"
        value={model.fields.phone || ''}
        onChange={(v) => onChangeField('phone', v)}
        onBlur={() => onBlurField('phone')}
        error={model.errors?.phone}
      />
      <div className={styles.paymentAgreement}>
        <Checkbox
          name="agreement"
          value={model.agreement}
          onChange={onChangeAgreement}
          label={(
            <>
              Принимаю условия <a href={model?.market?.offer} target="_blank" rel="noreferrer">Договора офферты</a> и <a onClick={openConditions}>Условия обработки персональных данных</a>
            </>
          )}
          error={model.errors?.agreement}
        />
      </div>
    </div>
  );
};

export default React.memo(PaymentInfo);
