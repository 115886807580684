import {createSlice}            from '@reduxjs/toolkit';
import {STATUS}                 from "store/consts";
import {PAYMENT_PAGE}           from "pages/payment/consts";
import {
  startPaymentProcess,
  checkPaymentStatus
}                               from "./thunk";

import type {PaymentInfo}       from "pages/payment/Model/PaymentInfo";

interface PaymentState {
  loading: valueof<typeof STATUS>;
  submitting: boolean;
  paymentPage: valueof<typeof PAYMENT_PAGE> | null;
  email: string;
  paymentInfo: PaymentInfo | null;
}

const initialState: PaymentState = {
  loading: STATUS.FULFILLED,
  submitting: false,
  paymentPage: null,
  email: '',
  paymentInfo: null
};

const counterSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentPage(state, {payload}) {
      state.paymentPage = payload;
    },
    setEmail(state, {payload}) {
      state.email = payload;
    },
    closePayment(state) {
      state.paymentPage = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(startPaymentProcess.fulfilled, (state) => {
      state.submitting = false;
    });
    builder.addCase(startPaymentProcess.pending, (state) => {
      state.submitting = true;
    });
    builder.addCase(startPaymentProcess.rejected, (state) => {
      state.submitting = false;
    });
    builder.addCase(checkPaymentStatus.fulfilled, (state, {payload}) => {
      state.paymentInfo = payload;
      state.loading = STATUS.FULFILLED;
    });
    builder.addCase(checkPaymentStatus.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(checkPaymentStatus.rejected, (state) => {
      state.loading = STATUS.REJECTED;
    });
  }
});

export const {
  setPaymentPage,
  closePayment,
  setEmail
} = counterSlice.actions;
export default counterSlice.reducer;
