export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const CHECK_STATUS_REQUEST = 15_000;
export const CHECK_STATUS_TIMER = 20_000;
export const CHECK_STATUS_TIMER_CHECKING= 15_000;

export const LOG_TYPES = Object.freeze({
  INPUT_ACTIVATED: 'INPUT activated',
  INPUT_FILLED: 'INPUT filled',
  BTN_SERVICE_CHOOSE: 'BTN Choose Service Press',
  BTN_SERVICE_ABOUT: 'BTN About Service Press',
  BTN_SERVICE_SUBMIT: 'BTN Submit Service Order Press',
  BTN_CAR_REQUEST_PRESS: 'BTN Car Request Press',
  BTN_CAR_REQUEST_PRESS_NOW: 'BTN Car Request Press Now',
  SESSION_PAGE_OPEN: 'Session Page Open',
  SERVICE_LIST_PAGE_OPEN: 'Service List Page Open',
  SERVICE_PAGE_ABOUT_SERVICES: 'Session Page About Services',
  BTN_TRY_GAS_STATION_SERVICE_YES: 'BTN Yes Try GasStation Service',
  BTN_TRY_GAS_STATION_SERVICE_NO: 'BTN No Try GasStation Service',
  SERVICE_PAGE_GAS_STATION_SERVICES: 'Session Page GasStation Services',
  SERVICE_PAGE_ORDER_OPEN: 'Service Order Page Open',
  SERVICE_PAGE_ORDER_SUCCESS_OPEN: 'Service Order Success Page Open',
  HOME_PAGE_OPENED: 'Home Page Open',
  SUBSCRIPTION_CARD_PRESS: 'Subscription Card Press',
  SUBSCRIPTION_MODAL_OPEN: 'Subscription Modal Open',
  SESSION_PAYMENT_MODAL_OPEN: 'Valet Session Payment Modal Open',
  BTN_INIT_PAYMENT: 'BTN Init Pay',
  PAYMENT_CALLBACK_STATUS: 'Callback Status',
  SESSION_CAR_REQUEST: 'Valet Service Car Request'
});

export const LOG_ERRORS = Object.freeze({
  CREATE_SERVICE_ORDER: 'Create Service Order Error'
});
