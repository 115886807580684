import {memo}   from "react";
import Submission  from "pages/submission/views";

function SubmissionModule() {
  return (
    <Submission/>
  );
}

export default memo(SubmissionModule);
