import {createSlice}                      from '@reduxjs/toolkit';
import {
  checkStatusRequest,
  getParkingInfo, sendBookRequest
}                                         from "store/parking/thunk";
import {STATUS}                           from "store/consts";
import {getDateByTime, getDateNow}        from "utils/date";
import {DELIVERY_TYPE, TIME_STATUSES}     from "pages/submission/consts";

import type {ParkingInfo}                 from "pages/home/Model/ParkingInfo";
import type {Dayjs}                       from "dayjs";

interface ParkingState {
  loading: valueof<typeof STATUS>;
  infoLoading: {status: valueof<typeof STATUS>, error: number | null };
  parkingInfo: ParkingInfo | null;
  state: number | null;
  currentTime: Dayjs;
  deliveryTime?: Dayjs;
  startedTime?: Dayjs;
  watchStatus: boolean;
  showProgress: boolean;
  showServiceOrder: boolean;
  orderInProgress: boolean;
  timeTab: valueof<typeof TIME_STATUSES>;
  deliveryType: valueof<typeof DELIVERY_TYPE>;
  selectedTime: {hour: number, minute: number};
  hasDebt: boolean;
  submitting: boolean;
}

const initialState: ParkingState = {
  loading: STATUS.FULFILLED,
  infoLoading: {status: STATUS.LOADING, error: null},
  parkingInfo: null,
  state: null,
  currentTime: getDateNow(),
  watchStatus: false,
  showProgress: false,
  orderInProgress: false,
  showServiceOrder: false,
  timeTab: TIME_STATUSES.TODAY,
  deliveryType: DELIVERY_TYPE.NOW,
  selectedTime: {hour: 0, minute: 0},
  hasDebt: false,
  submitting: false
};

const counterSlice = createSlice({
  name: 'parking',
  initialState,
  reducers: {
    updateCurrentTime(state) {
      state.currentTime = getDateNow();
    },
    updateTimeSubmission(state, {payload}) {
      if (!payload) {
        // false - Когда закрываем submission
        state.timeTab = TIME_STATUSES.TODAY;
        state.deliveryType = DELIVERY_TYPE.NOW;
        state.selectedTime = {hour: 0, minute: 0};
      } else {
        // true - Когда отрываем submission
        const time = getDateNow().add(10, 'minutes');
        state.selectedTime = {hour: time.get('hours'), minute: time.get('minutes')};
      }
    },
    setTimeTab(state, {payload}) {
      state.timeTab = payload;

      if (payload === TIME_STATUSES.TODAY) {
        const time = getDateNow().add(10, 'minutes');
        state.selectedTime = {hour: time.get('hours'), minute: time.get('minutes')};
      } else {
        state.selectedTime = {hour: 0, minute: 0};
      }
    },
    setDeliveryType(state, {payload}) {
      state.deliveryType = payload;
    },
    setSelectedTime(state, {payload}) {
      state.selectedTime = {...state.selectedTime, hour: payload.hour, minute: payload.minute};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getParkingInfo.fulfilled, (state, {payload}) => {
      state.loading = STATUS.FULFILLED;
      state.infoLoading = {status: STATUS.FULFILLED, error: null};
      state.parkingInfo = payload;
      state.state = payload.state;
      state.startedTime = getDateByTime(payload.started_at);

      if (payload?.car_delivery_time) {
        if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(payload.car_delivery_time)) {
          const formatDate = payload.car_delivery_time.split(' ').join('T') + ':00Z' as unknown as Date;
          state.deliveryTime = getDateByTime(formatDate).add(3, 'hours');
        } else {
          state.deliveryTime = getDateByTime(payload.car_delivery_time).add(3, 'hours');
        }
      }

      if (payload.state && [4, 5, 6, 7, 8].includes(payload.state)) {
        state.showProgress = true;
      }
      if (payload.state && !state.watchStatus && [4, 5, 6, 7].includes(payload.state)) {
        state.watchStatus = true;
      }

      if (payload.order_yc?.[0]?.status && payload.order_yc?.[0]?.status !== 0) {
        state.showServiceOrder = true;

        if (payload.order_yc?.[0]?.status !== 10) {
          state.orderInProgress = true;
        }
      }

      if (payload?.payment_data?.session_cost && payload?.payment_data?.payment_status) {
        state.hasDebt = payload?.payment_data?.payment_status === 1 &&  payload?.payment_data?.session_cost < 0;
      }

    });
    builder.addCase(getParkingInfo.pending, (state) => {
      state.loading = STATUS.LOADING;
      state.infoLoading = {status: STATUS.LOADING, error: null};
    });
    builder.addCase(getParkingInfo.rejected, (state, {payload}) => {
      state.loading = STATUS.REJECTED;
      state.infoLoading = {status: STATUS.REJECTED, error: payload as number};
      console.error(payload, 'ERROR_CODE');
    });
    builder.addCase(sendBookRequest.fulfilled, (state, {payload}) => {
      state.loading = STATUS.FULFILLED;
      state.parkingInfo = payload;
      state.state = payload.state;
      state.submitting = false;

      if (payload?.car_delivery_time) {
        if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(payload.car_delivery_time)) {
          const formatDate = payload.car_delivery_time.split(' ').join('T') + ':00Z' as unknown as Date;
          state.deliveryTime = getDateByTime(formatDate).add(3, 'hours');
        } else {
          state.deliveryTime = getDateByTime(payload.car_delivery_time).add(3, 'hours');
        }
      }

      if (payload.state && [4, 5, 6, 7, 8].includes(payload.state)) {
        state.showProgress = true;
      }
      if (payload.state && !state.watchStatus && [4, 5, 6, 7].includes(payload.state)) {
        state.watchStatus = true;
      }

    });
    builder.addCase(sendBookRequest.pending, (state) => {
      state.loading = STATUS.LOADING;
      state.submitting = true;
    });
    builder.addCase(sendBookRequest.rejected, (state, {payload}) => {
      state.loading = STATUS.REJECTED;
      state.submitting = false;
      console.error(payload, 'ERROR_CODE');
    });
    builder.addCase(checkStatusRequest.fulfilled, (state, {payload}) => {
      state.loading = STATUS.FULFILLED;

      if (payload?.status) {
        state.state = payload.status;

        if (payload.status > 7 || payload.status < 4) {
          state.watchStatus = false;
        }

        if (payload.status > 8 || payload.status < 4) {
          state.showProgress = false;
        }
      }
    });
    builder.addCase(checkStatusRequest.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(checkStatusRequest.rejected, (state, {payload}) => {
      state.loading = STATUS.REJECTED;
      console.error(payload, 'ERROR_CODE');
    });
  }
});

export const {
  updateCurrentTime,
  updateTimeSubmission,
  setTimeTab,
  setSelectedTime,
  setDeliveryType
} = counterSlice.actions;
export default counterSlice.reducer;
