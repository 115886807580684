import React from "react";

import styles from './styles.module.scss';

interface InfoCardProps {
  startTime: string;
  parkingTime: string | null;
  valetCard: string;
  place: string;
  image: string;
  model: string;
  number: string;
  tariff?: string;
  paid?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  startTime,
  parkingTime,
  valetCard,
  place,
  image,
  model,
  number,
  tariff,
  paid
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.image}>
          <img src={image} alt="car"/>
        </div>
        <div className={styles.block}>
          <div className={styles.model}>{model}</div>
          <div className={styles.number}>{number}</div>
        </div>
      </div>
      <dl>
        <div className={styles.option}>
          <dt>Время приёма авто:</dt>
          <dd>{startTime}</dd>
        </div>
        <div className={styles.option}>
          <dt>Время на парковке:</dt>
          <dd>{parkingTime}</dd>
        </div>
        <div className={styles.option}>
          <dt>Номер Valet-карты</dt>
          <dd>{valetCard}</dd>
        </div>
        <div className={styles.option}>
          <dt>Место на парковке:</dt>
          <dd>{place}</dd>
        </div>
        {tariff && (
          <div className={styles.option}>
            <dt>Тариф:</dt>
            <dd>{tariff}</dd>
          </div>
        )}
        {paid && (
          <div className={styles.option}>
            <dt>Оплачено:</dt>
            <dd className={styles.sum}>{paid}₽</dd>
          </div>
        )}
      </dl>
    </div>
  );
};
