export const IconInfo = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.05847 1C3.71247 1 1 3.71247 1 7.05847C1 10.4045 3.71247 13.1169 7.05847 13.1169C10.4045 13.1169 13.1169 10.4045 13.1169 7.05847C13.1169 3.71247 10.4045 1 7.05847 1Z"
        stroke="#C3C7DB" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.05859 9.48187L7.05859 7.05848" stroke="#C3C7DB" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.05859 4.63507L7.05254 4.63507" stroke="#C3C7DB" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
