import React from "react";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface CheckboxProps {
  name: string;
  label: string | React.ReactElement | React.ReactElement[];
  value: boolean;
  onChange: (val: boolean) => void;
  error?: string | null;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  onChange,
  value = false,
  error
}) => {
  return (
    <div>
      <div className={styles.checkbox}>
          <div className={styles.checkboxWrapper}>
            <input className={styles.checkboxInput} id={name} name={name} type="checkbox" defaultChecked={value} onClick={() => onChange(!value)} />
            <label className={clsx(styles.checkboxLabel, {[styles.checkboxChecked]: value})} htmlFor={name}  />
          </div>
          <div className={styles.checkboxText}>{label}</div>
      </div>
      {!!error?.length && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Checkbox;
