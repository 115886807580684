export interface ValidationErrors {
  name: string | null
  phone: string | null
}

export const validatePhone = (val: string| null): string | null => {
  if (val) {
    const value = val.replace(/\D+/g, "");

    if (value.length !== 11) {
      return "Неверный номер";
    } else {
      return null;
    }
  } else {
    return "Необходимо корректно ввести номер";
  }
};

export const validateName = (val: string | null): string | null => {
  if (val) {
    return null;
  } else {
    return "Необходимо заполнить имя";
  }
};
