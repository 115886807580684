import React, {
  memo, useCallback,
  useEffect, useState
}                             from "react";
import {useSelector}          from "react-redux";
import {useAppDispatch}       from "store";
import {setModule}            from "store/modules/slice";
import {closePayment}         from "store/payment/slice";
import {About}                from "pages/payment/views/About";
import {Process}              from "pages/payment/views/Process";
import {BottomSheet}          from "components/BottomSheet";
import {PAYMENT_PAGE}         from "pages/payment/consts";

import type {RootState}       from "store";

import {clsx}                 from "clsx";
import styles                 from "./styles.module.scss";

function Payment() {
  const model = useSelector((s: RootState) => s);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const {paymentPage} = model.payment;

  const handleClose = useCallback(() => {
    setIsOpen(false);
    dispatch(setModule(null));
    dispatch(closePayment());
  }, []);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  let content, title;

  if (paymentPage === PAYMENT_PAGE.ABOUT) {
    title = `Тарифы и&nbsp;условия <br /> услуги валет-парковки`;
    content = <About />;
  } else if (paymentPage === PAYMENT_PAGE.PROCESS) {
    title = "Подадим автомобиль за&nbsp;9&nbsp;минут после&nbsp;оплаты валет-парковки";
    content = <Process />;
  }

  return (
    <>
      <BottomSheet
        className={clsx({[styles.padding]: paymentPage === PAYMENT_PAGE.PROCESS})}
        isOpen={isOpen}
        handleClose={handleClose}
        title={title}
        isScrollable
      >
        {content}
      </BottomSheet>
    </>
  );
}

export default memo(Payment);
