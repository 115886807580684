import React, {useRef} from 'react';
import Sheet, {SheetRef} from 'react-modal-sheet';
import {LENGTH} from "components/BottomSheet/consts";

import {clsx} from "clsx";
import styles from "./index.module.scss";

interface BottomSheetProps {
  isOpen: boolean;
  children: React.ReactNode | React.ReactNode[];
  handleClose: () => void;
  title?: string;
  header?: React.ReactNode | React.ReactNode[];
  isScrollable?: boolean;
  className?: string;
  description?: string;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  children,
  handleClose,
  title,
  header,
  isScrollable,
  className,
  description
}) => {
  const ref = useRef<SheetRef>(null);
  let headerEl, content;

  if (header) {
    headerEl = header;
  } else {
    headerEl = (
      <div className={styles.header}>
        <div className={styles.line}/>
        {title && <p className={styles.title} dangerouslySetInnerHTML={{__html: title}}/>}
        {description && <p className={styles.description}>{description}</p>}
      </div>
    );
  }

  if (isScrollable) {
    content = (
      <Sheet.Scroller>
        <div className={clsx(styles.scrollableContent, className)}>
          {children}
        </div>
      </Sheet.Scroller>
    );
  } else {
    content = (
      <Sheet.Content disableDrag>
        {children}
      </Sheet.Content>
    );
  }

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => handleClose()}
      detent={isScrollable ? LENGTH.FULL : LENGTH.CONTENT}
      ref={ref}
    >
      <Sheet.Container data-scrollable={isScrollable}>
        <Sheet.Header>
          {headerEl}
        </Sheet.Header>
        {content}
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => handleClose()} />
    </Sheet>
  );
};
