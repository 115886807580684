import React, {memo, useCallback}             from 'react';
import {useSelector}                          from "react-redux";
import {useAppDispatch}                       from "store";
import {Info}                                 from 'pages/home/views/components/Order/Info';
import {Button}                               from "components/Button";
import {BTN_COLOR}                            from "components/Button/consts";
import {setModule}                            from "store/modules/slice";
import MODULES                                from "pages/modules";
import {DeliveryProgress}                     from "components/ProgressBar/DeliveryProgress";
import {ServiceProgress}                      from "components/ProgressBar/ServiceProgress";
import {setPaymentPage}                       from "store/payment/slice";
import {PAYMENT_PAGE}                         from "pages/payment/consts";
import {Loader}                               from "components/Loader";
import {log}                                  from "utils/analytics";
import {LOG_TYPES}                            from "utils/consts";

import styles                                 from "./styles.module.scss";

import type {RootState}                       from "store";

export const Order: React.FC = memo(() => {
  const {parkingInfo, orderInProgress, showProgress, hasDebt, state, submitting} = useSelector((s: RootState) => s.parking);
  const dispatch = useAppDispatch();
  const order = parkingInfo?.order_yc?.[0];
  const isShowOrderProgress = orderInProgress && order?.status && [2,3].includes(order.status)

  const handleOpen = useCallback(() => {
    if (hasDebt) {
      dispatch(setModule(MODULES.PAYMENT));
      dispatch(setPaymentPage(PAYMENT_PAGE.PROCESS));
    } else {
      dispatch(setModule(MODULES.SUBMISSION));
    }
    log(LOG_TYPES.BTN_CAR_REQUEST_PRESS, parkingInfo);
  }, [hasDebt, parkingInfo]);

  let btn;
  if (submitting) {
    btn = <Button className={styles.submitting} color={BTN_COLOR.GREEN_STICKY}><Loader className={styles.loader} />Запрашиваем подачу...</Button>;
  } else if (isShowOrderProgress && !showProgress) {
    btn = <ServiceProgress/>;
  } else if (showProgress) {
    btn = <DeliveryProgress/>;
    // Когда авто выдано
  } else if (state && state > 8) {
    btn = null;
  } else {
    btn = <Button color={BTN_COLOR.GREEN_STICKY} onClick={handleOpen}>Подать автомобиль</Button>;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Info />
      </div>
      {btn}
    </>
  );
});
