import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {options} from "utils/analytics";
import Module from "./Module";

import 'assets/styles/main.scss';

function App() {
  useEffect(() => {
    // Аналитика
    if (typeof window !== 'undefined') {
      window.gtag('event', 'SessionView', {
        'app_name': 'mygetvalet',
        'screen_name': 'Home'
      });
      amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY as string, undefined, options);
    }
  }, []);

  return (
    <Module />
  );
}

export default App;
