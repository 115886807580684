export function getFormatPrice(str: string | number, useSuffix?: boolean): string {
  let num = str;

  if (typeof num === "number") {
    num = String(num);
  }

  if (num?.includes('.')) {
    num = num.split('.')[0];
  }

  if (useSuffix) {
    return new Intl.NumberFormat('ru-Ru').format(Number(num)) + ' ₽';
  } else {
    return new Intl.NumberFormat('ru-Ru').format(Number(num));
  }
}
