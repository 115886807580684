import {createSlice, current}       from '@reduxjs/toolkit';
import {STATUS}                     from "store/consts";
import {
  getObjectInfo, getCategoryInfo,
  getProductInfo
}
                                    from "./thunk";
import {MARKET_SCREEN}              from "pages/market/consts";

import type {
  Category, Marketplace, Product
}                                   from "pages/market/Model/Marketplace";
import type {CartItem}              from "pages/market/Model/Marketplace";

export interface MarketState {
  loading: valueof<typeof STATUS>;
  loadingCategory: valueof<typeof STATUS>;
  loadingProduct: valueof<typeof STATUS>;
  submitting: boolean;
  market: Marketplace | null;
  categories: {
    id: number;
    section: number;
    title: string;
    description: string;
    thumbImage?: string;
  }[] | null;
  objectName: string;
  fullAddress: string;
  image?: string;
  screen: valueof<typeof MARKET_SCREEN>
  categoryId?: number;
  productId?: number;
  category?: Category;
  product?: Product;
  cart: CartItem[];
  totalPrice: number;
  totalAmount: number;
  service?: null | {title: string; category: string; id: number; image?: string};
  openedConditions: boolean;
  fields: Dictionary;
  errors: Dictionary;
  touched: Dictionary;
  agreement: boolean;
}

const initialState: MarketState = {
  loading: STATUS.FULFILLED,
  loadingCategory: STATUS.FULFILLED,
  loadingProduct: STATUS.FULFILLED,
  submitting: false,
  screen: MARKET_SCREEN.MAIN,
  market: null,
  categories: null,
  objectName: '',
  fullAddress: '',
  cart: [],
  totalPrice: 0,
  totalAmount: 0,
  openedConditions: false,
  fields: {},
  errors: {},
  touched: {},
  agreement: false
};

const counterSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setScreen: (state, {payload}) => {
      state.screen = payload;
    },
    setCategoryId: (state, {payload}) => {
      state.categoryId = payload;

      if (!payload) {
        state.category = undefined;
      }
    },
    setProductId: (state, {payload}) => {
      state.productId = payload;

      if (!payload) {
        state.product = undefined;
      }
    },
    addItemCart: (state, {payload}) => {
      const cur = current(state.cart);
      if (cur?.length) {
        const item = cur.find(it => it.id === payload.id);
        if (item) {
          const filtered = cur.filter(it => it.id !== payload.id);
          state.cart = [...filtered, {...payload, amount: item.amount + 1}];
        } else {
          state.cart = [...cur, {...payload, amount: 1}];
        }
      } else {
        state.cart = [{...payload, amount:  1}];
      }
      state.totalAmount += 1;
      state.totalPrice += payload.price;
    },
    removeItemCart: (state, {payload}) => {
      const cur = current(state.cart);
      const item = cur.find(it => it.id === payload.id);
      if (item) {
        const filtered = cur.filter(it => it.id !== payload.id);
        if (item.amount > 1) {
          state.cart = [...filtered, {...payload, amount: item.amount - 1}];
        } else {
          state.cart = [...filtered];
        }
      }
      state.totalAmount -= 1;
      state.totalPrice -= payload.price;
    },
    deleteGroupCart: (state, {payload}) => {
      const cur = current(state.cart);

      let groupAmount = 0, groupPrice = 0;
      cur.forEach((it) => {
        if (it.categoryId === payload) {
          groupAmount += it.amount;
          groupPrice += (it.price * it.amount);
        }
      });

      state.totalPrice -= groupPrice;
      state.totalAmount -= groupAmount;

      const filtered = cur.filter(it => it.categoryId !== payload);
      state.cart = [...filtered];
    },
    setService: (state, {payload}) => {
      state.service = payload;
    },
    setAgreement: (state, {payload}) => {
      state.agreement = payload;
    },
    setError: (state, {payload}) => {
      const {name, value} = payload;
      state.errors[name] = value;
    },
    setTouched: (state, {payload}) => {
      const {name, value} = payload;
      state.touched[name] = value;
    },
    setField: (state, {payload}) => {
      const {name, value} = payload;
      state.fields[name] = value;
    },
    setOpenedConditions: (state, {payload}) => {
      state.openedConditions = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getObjectInfo.fulfilled, (state, {payload}) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.market = payload.marketplace;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.image = payload.mainCover;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.fullAddress = payload.fullAddress;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.objectName = payload.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.categories = payload.marketplace?.sections?.reduce((acc: {
        id: number;
        section: number;
        title: string;
        description: string;
        thumbImage?: string;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      }[], el) => {
        if (el?.categories?.length) {
          acc = [...acc, ...el.categories];
        }
        return acc;
      }, []);
      state.loading = STATUS.FULFILLED;
    });
    builder.addCase(getObjectInfo.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getObjectInfo.rejected, (state) => {
      state.loading = STATUS.REJECTED;
    });
    builder.addCase(getCategoryInfo.fulfilled, (state, {payload}) => {
      state.category = payload;
      state.loadingCategory = STATUS.FULFILLED;
    });
    builder.addCase(getCategoryInfo.pending, (state) => {
      state.loadingCategory = STATUS.LOADING;
    });
    builder.addCase(getCategoryInfo.rejected, (state) => {
      state.loadingCategory = STATUS.REJECTED;
    });
    builder.addCase(getProductInfo.fulfilled, (state, {payload}) => {
      state.product = payload;
      state.loadingProduct = STATUS.FULFILLED;
    });
    builder.addCase(getProductInfo.pending, (state) => {
      state.loadingProduct = STATUS.LOADING;
    });
    builder.addCase(getProductInfo.rejected, (state) => {
      state.loadingProduct = STATUS.REJECTED;
    });
  }
});

export const {
  setScreen,
  setCategoryId,
  setProductId,
  addItemCart,
  removeItemCart,
  setService,
  setAgreement,
  deleteGroupCart,
  setTouched,
  setField,
  setError,
  setOpenedConditions
} = counterSlice.actions;
export default counterSlice.reducer;
