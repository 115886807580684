import React, {useEffect} from "react";

export function useHeaderObserver(ref: React.RefObject<HTMLElement>) {
  useEffect(() => {
    const el = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('stickied', e.intersectionRatio < 1),
      { threshold: [1] }
    );
    if (!el) {
      return;
    }
    observer.observe(el);
    return () => {
      observer.disconnect();
    };
  }, []);
}
