import React, {
  memo, useCallback,
  useEffect, useState
}                             from "react";
import {useSelector}          from "react-redux";
import {useAppDispatch}       from "store";
import {setModule}            from "store/modules/slice";
import {closeService}         from "store/service/slice";
import {SERVICE_PAGE, STEPS}  from "pages/service/consts";
import {WashingList}          from "pages/service/views/WashingList";
import {Washing}              from "pages/service/views/Washing";
import {Fueling}              from "pages/service/views/Fueling";
import {About}                from "pages/service/views/About";
import {Info}                 from "pages/service/views/Info";
import {BottomSheet}          from "components/BottomSheet";

import type {RootState}       from "store";

import {clsx}                 from "clsx";
import styles                 from "./styles.module.scss";

function Service() {
  const model = useSelector((s: RootState) => s);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const {servicePage, serviceInfo, step} = model.service;
  const parkingInfo = model.parking.parkingInfo;

  const handleClose = useCallback(() => {
    setIsOpen(false);
    dispatch(setModule(null));
    dispatch(closeService());
  }, []);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  let content, title, description;

  if ((servicePage === SERVICE_PAGE.CAR_WASHING) && serviceInfo) {
    title = step === STEPS.LAST ? "Спасибо, услуга заказана!" : "Подтверждение заказа";
    content = <Washing />;
  } else if (servicePage === SERVICE_PAGE.GAS_STATION) {
    title = "Услуга заправки";
    content = <Fueling />;
  } else if (servicePage === SERVICE_PAGE.ABOUT) {
    title = "Порядок оказания услуги";
    content = <About />;
  } else if (servicePage === SERVICE_PAGE.CAR_WASHING && !serviceInfo) {
    title = "Выберите тип мойки";
    description = "Время оказания услуги от 1 часа";
    content = <WashingList />;
  } else if (servicePage === SERVICE_PAGE.INFO) {
    title = parkingInfo?.order_yc?.[0].title;
    content = <Info />;
  }

  const padding = servicePage === SERVICE_PAGE.CAR_WASHING && step === STEPS.INITIAL;

  return (
    <>
      <BottomSheet
        className={clsx({[styles.padding]: padding})}
        isOpen={isOpen}
        handleClose={handleClose}
        title={title}
        description={description}
        isScrollable
      >
        {content}
      </BottomSheet>
    </>
  );
}

export default memo(Service);
