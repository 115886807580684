import {memo}   from "react";
import Payment  from "pages/payment/views";

function PaymentModule() {
  return (
    <Payment />
  );
}

export default memo(PaymentModule);
