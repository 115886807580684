import React, {memo, useCallback, useEffect} from "react";
import {useAppDispatch}         from "store";
import {useSelector}            from "react-redux";
import {InfoPaymentCard}        from "pages/payment/views/components/InfoPaymentCard";
import {Field}                  from "components/Field";
import {Button}                 from "components/Button";
import {BTN_COLOR}              from "components/Button/consts";
import {setEmail}               from "store/payment/slice";
import {startPaymentProcess}    from "store/payment/thunk";
import {log}                    from "utils/analytics";
import {LOG_TYPES}              from "utils/consts";

import styles                   from "./styles.module.scss";

import type {RootState}         from "store";

export const Process: React.FC = memo(() => {
  const model = useSelector((s: RootState) => s);
  const dispatch = useAppDispatch();
  const {parkingInfo, currentTime, startedTime} = model.parking;
  const submitting = model.payment.submitting;

  const onChangeEmail = useCallback((val: string) => {
    dispatch(setEmail(val));
  }, [dispatch]);

  const handleStartPayment = useCallback(() => {
    if (!submitting) {
      dispatch(startPaymentProcess());
    }
  }, [dispatch, submitting]);


  useEffect(() => {
    const isFreeParking = parkingInfo?.payment_data?.message === 'Парковка бесплатная';

    log(LOG_TYPES.SESSION_PAYMENT_MODAL_OPEN, null, {
      SubscriptionID: parkingInfo?.subscription?.id,
      SubscriptionName: parkingInfo?.subscription?.title,
      SessionStatus: parkingInfo?.state,
      ObjectID: parkingInfo?.parking?.id,
      SessionCost: parkingInfo?.payment_data?.session_cost,
      FreeParking: isFreeParking,
      PaymentStatus: parkingInfo?.payment_data?.payment_status,
      Duration: parkingInfo?.payment_data?.duration,
      TariffTitle: parkingInfo?.payment_data?.tariff?.title,
      TariffID: parkingInfo?.payment_data?.tariff?.id
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <InfoPaymentCard parkingInfo={parkingInfo} currentTime={currentTime} startedTime={startedTime} />
      <div className={styles.card}>
        <div className={styles.title}>Email для чека (необязательно):</div>
        <div className={styles.text}>Чек даёт вам гарантию того, что услуга оплачена. Если вы откажитесь, мы всё равно
          его сохраним.
        </div>
        <Field placeholder="Введите ваш email" name="email" value={model?.payment.email} onChange={onChangeEmail} inputMode="text"/>
      </div>
      <div className={styles.card}>
        <div className={styles.title}>Тарифы и условия валет-парковки:</div>
        <div className={styles.text}>Стоимость валет-парковки составляет: {parkingInfo?.payment_data.tariff.title || '-'}</div>
      </div>
      <div className={styles.btn} >
        <Button loading={submitting} color={BTN_COLOR.GREEN_STICKY} onClick={handleStartPayment}>{submitting ? "Получаем ответ от банка" : "Перейти к оплате"}</Button>
      </div>
    </div>
  );
});
