import {MARKET_SCREEN, PRODUCT_TYPE}    from "pages/market/consts";
import {BOTTOM_TYPE}                    from "./consts";
import {STATUS}                         from "store/consts";

import type {MarketState} from "store/market/slice";

export const getBottomType = (model: MarketState): string | null => {
  const isCommonProduct = model?.product?.productType === PRODUCT_TYPE.GOODS;
  const isShowCart = (model.screen === MARKET_SCREEN.CATEGORY_LIST || model.screen === MARKET_SCREEN.MAIN)
  && model.totalAmount > 0;

  switch (true) {
    case model.screen === MARKET_SCREEN.PAYMENT_SERVICE && !!model?.service:
      return BOTTOM_TYPE.PAYMENT_SERVICE;
    case model.screen === MARKET_SCREEN.PAYMENT_GOODS && !model?.service && !!model?.cart?.length:
      return BOTTOM_TYPE.PAYMENT_PRODUCT;
    case model.screen === MARKET_SCREEN.PRODUCT && model.loadingProduct !== STATUS.LOADING && !isCommonProduct:
      return BOTTOM_TYPE.SERVICE;
    case model.screen === MARKET_SCREEN.PRODUCT && model.loadingProduct !== STATUS.LOADING && isCommonProduct:
      return BOTTOM_TYPE.PRODUCT;
    case isShowCart:
      return BOTTOM_TYPE.COMMON_CART;
    default:
      return null;
  }
};
