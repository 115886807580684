import React, {useEffect, memo}     from "react";
import {useSelector}                from "react-redux";
import {useAppDispatch}             from "store";
import {getServiceList}             from "store/service/thunk";
import {setService}                 from "store/service/slice";
import {Loader}                     from "components/Loader";
import {STATUS}                     from "store/consts";
import {ServiceCard}                from "pages/service/views/components/ServiceCard";
import {log}                        from "utils/analytics";
import {LOG_TYPES}                  from "utils/consts";
import {SERVICE_TYPE_TEXT}          from "pages/service/consts";

import styles                       from "./styles.module.scss";

import type {RootState}             from "store";

export const WashingList: React.FC = memo(() => {
  const {service : {productList, loading, category}, parking: {parkingInfo, state}} = useSelector((s: RootState) => s);
  const dispatch = useAppDispatch();

  const isHasList = loading === STATUS.FULFILLED && productList?.length;
  const isAvailable = state && state <= 3;

  useEffect(() => {
    dispatch(getServiceList());
    log(LOG_TYPES.SERVICE_LIST_PAGE_OPEN, parkingInfo, {
      ServiceType: category && SERVICE_TYPE_TEXT[category]
    });
  }, []);

  const handleSetService = (id: number, title: string, price: number) => {
    log(LOG_TYPES.BTN_SERVICE_CHOOSE, parkingInfo, {
      ServiceType: category && SERVICE_TYPE_TEXT[category],
      ServiceName: title,
      ServicePrice: price
    });
    dispatch(setService(id));
  };

  const content = (
    <>
      {!isAvailable && (
        <div className={styles.notActive}>
          Упс, Ваш автомобиль сейчас на подаче. Услуги мойки достпны к заказу только когда автомобиль находится на
          парковке. Возвращайтесь скорее! 🙂
        </div>
      )}
      <div className={styles.list}>
        {
          productList?.map((el, i) => (
            <ServiceCard key={i} service={el} handleSetService={handleSetService} isAvailable={!!isAvailable} />
          ))
        }
      </div>
    </>
  );

  return (
    <>
      {isHasList ? content : <Loader />}
    </>
  );
});
