export const TIME_STATUSES = {
  TODAY: 'today',
  TOMORROW: 'tomorrow'
} as const;

export const DELIVERY_TYPE = {
  SELECTED_TIME: 'selected_time',
  NOW: 'now'
} as const;

export const TABS = [
  {title: 'Сегодня', value: TIME_STATUSES.TODAY},
  {title: 'Завтра', value: TIME_STATUSES.TOMORROW}
];
