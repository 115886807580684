import React, {
  memo, useEffect, useMemo,
  useRef, useState
}                                     from 'react';
import {useSelector}                  from "react-redux";
import {Icon}                         from 'components/Icon';

import styles                         from './styles.module.scss';

import type {RootState}               from "store";

const MAX_HEIGHT = 200;

export const Top: React.FC<{image?: string; topImage?: string;}> = memo(({image}) => {
  const [offset, setOffset] = useState<number>(0);
  const {parkingInfo} = useSelector((s: RootState) => s.parking);
  const imgRef = useRef(null);

  function onScroll() {
    if (typeof window !== 'undefined') {
      const offsetY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      setOffset(offsetY);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return (() => window.removeEventListener('scroll', onScroll));
  }, []);

  const imgHeight = useMemo(() => {
    return MAX_HEIGHT - offset;
  }, [offset]);

  const img = image || parkingInfo?.parking?.picture;

  return (
    <div
      className={styles.header}
    >
      <div className={styles.inner}>
        <div className={styles.logo}><Icon.Logo/></div>
        <div ref={imgRef} className={styles.img} style={{ height: imgHeight }}>
          {img && <img src={img} alt="place" /> }
        </div>
      </div>
    </div>
  );
});

