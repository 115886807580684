import React, {memo} from "react";
import {formatPhone} from "./helpers";

import styles from "./styles.module.scss";

interface FieldProps {
  name: string
  placeholder?: string
  value: string
  onChange: (v: string) => void
  onBlur?: (v: string) => void
  onFocus?: () => void
  inputMode: 'text' | 'tel'
  error?: string | null
  maxLength?: number
  label?: string
}

export const Field: React.FC<FieldProps> = memo(({
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  inputMode,
  error,
  maxLength
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value;
    if (name === 'phone') {
      value = formatPhone(e.target.value);
    } else {
      value = e.target.value;
    }
    onChange(value);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBlur && onBlur(e.target.value);
  };

  const handleFocus = () => {
    onFocus && onFocus();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (name === "phone") {
      const inputValue = value.replace(/\D/g, '');
      if (e.key == "Backspace" && inputValue.length == 1) {
        onChange("");
      }
    }
  };

  return (
    <div className={styles.inputContainer}>
      {/*{!!label?.length && <span className={styles.placeholder}>{label}</span>}*/}
      <input
        className={styles.input}
        type="text"
        inputMode={inputMode}
        name={name}
        placeholder={placeholder}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        maxLength={maxLength}
      />
      {!!error?.length && <div className={styles.error}>{error}</div>}
    </div>
  );
});
