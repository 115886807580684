import React, {memo, useEffect, useMemo} from 'react';
import {useSelector}            from "react-redux";
import {useAppDispatch}         from "store";
import Home                     from "pages/home";
import Service                  from "pages/service";
import Payment                  from "pages/payment";
import Submission               from "pages/submission";
import MODULES                  from "pages/modules";
import {
  CHECK_STATUS_TIMER,
  CHECK_STATUS_TIMER_CHECKING,
  LOG_TYPES
}                               from "utils/consts";
import {updateCurrentTime}      from "store/parking/slice";
import {log}                    from "utils/analytics";
import getPercentFromTimes      from "utils/getPercentFromTimes";
import {StatusPage}             from "pages/payment/views/StatusPage";
import {SubscriptionStatusPage} from "./payment/views/SubscriptionStatusPage";
import getQueryParams           from "utils/getQueryParams";

import type {RootState}         from "store";

function Module() {
  const {paymentStatus, id, place, productType, orderId} = useMemo(() => getQueryParams(), []);
  const {modules: {module}, parking: {watchStatus, state, parkingInfo, deliveryTime, currentTime}} = useSelector((s: RootState) => s);
  const dispatch = useAppDispatch();

  const percentsToDone = useMemo(() => {
    const createdAt = parkingInfo?.request?.created_at;
    if (createdAt && deliveryTime) {
      return getPercentFromTimes(createdAt, deliveryTime.subtract(3, 'hours'), currentTime);
    } else {
      return null;
    }
  }, [deliveryTime, currentTime, parkingInfo]);

  useEffect(() => {
    const delay = watchStatus && percentsToDone ? CHECK_STATUS_TIMER_CHECKING : CHECK_STATUS_TIMER;

    const timerInterval = setInterval(() => {
      dispatch(updateCurrentTime());
    }, delay);

    return () => {
      clearInterval(timerInterval);
    };
  }, [watchStatus, percentsToDone]);

  useEffect(() => {
    if (parkingInfo) {
      log(LOG_TYPES.SESSION_PAGE_OPEN, parkingInfo, {
        SessionStatus: state,
        ObjectID: parkingInfo.id
      });
    }
  }, [parkingInfo]);

  let Component = null;

  if (module === MODULES.SERVICE) {
    Component = <Service/>;
  }
  if (module === MODULES.PAYMENT) {
    Component = <Payment/>;
  }
  if (module === MODULES.SUBMISSION) {
    Component = <Submission/>;
  }

  if (!productType && (paymentStatus === 'true' || paymentStatus === 'false')) {
    return <StatusPage isError={paymentStatus === 'false'} id={id} place={place} />;
  }

  if (productType === 'subscription' && orderId && (paymentStatus === 'true' || paymentStatus === 'false')) {
    return <SubscriptionStatusPage orderId={orderId} isError={paymentStatus === 'false'} />;
  }

  return (
    <div className="page">
      {Component}
      <Home />
    </div>
  );
}

export default memo(Module);
