import React, {memo, useRef} from "react";
import {useHeaderObserver} from "utils/hooks/useHeaderObserver";

import styles from "./styles.module.scss";
import {clsx} from "clsx";

interface Props {
  title?: string;
  description?: string;
  fullTitle?: boolean;
}

export const HeaderName: React.FC<Props> = memo(({title, description, fullTitle}) => {
  const headerRef = useRef(null);
  useHeaderObserver(headerRef);

  return (
    <div className={styles.header} ref={headerRef}>
      <div className={clsx(styles.title, {[styles.fullTitle]: fullTitle})}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
});
