import dayjs, {Dayjs} from 'dayjs';
import timezone       from 'dayjs/plugin/timezone';
import utc            from 'dayjs/plugin/utc';
import advanced       from 'dayjs/plugin/advancedFormat';
import duration       from 'dayjs/plugin/duration';
import 'dayjs/locale/ru';

dayjs.locale('ru');
dayjs.extend(timezone);
dayjs.extend(utc, {keepLocalTime: false});
dayjs.tz.setDefault("Europe/Moscow");
dayjs.extend(advanced);
dayjs.extend(duration);

export const FULL_DATE_FORMAT =  'D MMMM YYYY в HH:mm';

export function getDateNow() {
  return dayjs();
}

export function getDateByTime(date?: Date | Dayjs | null) {
  return dayjs(date);
}

export function differenceInTime(prevDate: Dayjs, currentDate: Dayjs, withSeconds?: boolean, withSuffix?: boolean): string | number[] {
  const duration = dayjs.duration(currentDate.diff(prevDate));
  const suffix = ['л', 'м', 'д', 'ч', 'мин', 'сек'];

  const values = [
    duration.years(),
    duration.months(),
    duration.days(),
    duration.hours(),
    duration.minutes()
  ];

  if (withSeconds) {
    values.push(duration.seconds());
  }

  if (values.reduce((acc, v) => acc + v, 0) === 0 && duration.seconds() > 0) {
    values.push((duration.seconds() / 5) * 5);
  }

  if (withSuffix) {
    return values.map((value, index) => {
      if (value > 0) {
        return values[index] + suffix[index] + ' ';
      } else {
        return '';
      }
    }).join(' ').trim();
  }
  return values;
}

export function getDurationTime(startTime: Dayjs, currentTime: Dayjs, suffix?: boolean): string {
  return differenceInTime(startTime, currentTime, false, suffix) as string;
}

// Префикс Сегодня/Вчера иначе возвращаем текущую дату
export function getPrefixDate(startTime?: Dayjs, currentTime?: Dayjs): string | null {
  if (!startTime || !currentTime) {
    return null;
  } else {
    const duration = dayjs.duration(currentTime.diff(startTime));
    const days = duration.days();

    if (days === 0) {
      return startTime?.format('Сегодня в HH:mm');
    }
    if (days === 1) {
      return startTime?.format('Вчера в HH:mm');
    }
    return startTime?.format('DD.MM.YYYY в HH:mm');
  }
}
