import React from "react";

import styles from "./styles.module.scss";

const EmptyProducts = () => {
  return (
    <>
      <div className={styles.emptyItem} />
      <div className={styles.emptyItem} />
      <div className={styles.emptyItem} />
      <div className={styles.emptyItem} />
    </>
  );
};

export default React.memo(EmptyProducts);
