import React, {memo}  from "react";

import {clsx}         from "clsx";
import styles         from "./styles.module.scss";

interface TabsProps {
  tabs: {title: string, value: string | number | null}[];
  active: string | number | null;
  onClick: (val: string | number | any) => void;
  className?: string;
}

export const Tabs: React.FC<TabsProps> = memo(({tabs, onClick, active, className}) => {
  return (
    <div className={clsx(styles.tabs, className)}>
      {tabs.map(({title, value}, i) => (
        <div
          key={i}
          data-active={active === value}
          className={styles.tab}
          onClick={() => onClick(value)}
        >
          {title}
        </div>
      ))}
    </div>
  );
});
