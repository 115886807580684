interface Modules {
  SERVICE: string;
  PAYMENT: string;
  SUBMISSION: string;
}

export const MODULES = {} as Modules;
export default MODULES;

MODULES.SERVICE = 'service';
MODULES.SUBMISSION = 'submission';
MODULES.PAYMENT = 'payment';
