export default function getQueryParams() {
  const str = decodeURIComponent(document.location.search);
  const params = new URLSearchParams(str);
  let place = params.get("P");
  let id = params.get("VCID");
  let paymentStatus = params.get("paymentStatus");
  let productType = params.get("productType");
  let orderId = params.get("orderId");

  if (id) {
    id = id.replace(/ /g, "");
  }
  if (place) {
    place = place.replace(/ /g, "");
  }
  if (paymentStatus) {
    paymentStatus = paymentStatus.replace(/ /g, "");
  }
  if (productType) {
    productType = productType.replace(/ /g, "");
  }
  if (orderId) {
    orderId = orderId.replace(/ /g, "");
  }

  return {
    place,
    id,
    paymentStatus,
    productType,
    orderId
  };
}
