import React, {memo, useRef} from "react";
import {useHeaderObserver} from "utils/hooks/useHeaderObserver";
import {Icon} from "components/Icon";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface HeaderProps {
  title?: string;
  text?: string;
  close?: () => void;
  back?: () => void;
  reversed?: boolean;
  loading?: boolean;
}

export const Header: React.FC<HeaderProps> = memo(({title, text, close, back, reversed, loading}) => {
  const headerRef = useRef(null);
  useHeaderObserver(headerRef);

  let content;
  switch (true) {
    case loading && reversed:
      content = (
        <>
          <div className={styles.loadingSmallText}/>
          <div className={styles.loadingTitle}/>
        </>
      );
      break;
    case loading && !reversed:
      content = (
        <>
          <div className={styles.loadingTitle}/>
          <div className={styles.loadingText}/>
        </>
      );
      break;
    case !loading && reversed:
      content = (
        <>
          <div className={styles.smallText}>{text}</div>
          <div className={styles.title}>{title}</div>
        </>
      );
      break;
    case !loading && !reversed:
      content = (
        <>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{text}</div>
        </>
      );
      break;
  }

  return (
    <div ref={headerRef} className={clsx(styles.top, {[styles.hasAction]: back || close})}>
        {back && <div className={styles.back} onClick={back}><Icon.Back/></div>}
        {content}
        {close && <div className={styles.close} onClick={close}><Icon.Close/></div>}
    </div>
  );
});
