export const SERVICE = {
  CAR_WASHING: 13220956,
  GAS_STATION: 13242525
} as const;

export const SERVICE_PAGE = {
  CAR_WASHING: 'carwash',
  GAS_STATION: 'gasstaion',
  ABOUT: 'about',
  INFO: 'info'
} as const;

export const SERVICE_TYPE_TEXT = {
  13220956: 'carwash',
  13242525: 'gasstaion'
} as const;

export const STEPS ={
  INITIAL: 'initial',
  LAST: 'last'
} as const;

export const YC_COMPANY = 763695;

export const SERVICE_STATUSES = {
  0: "Отменён", // Не выводим блок
  1: "Ожидает подтверждения",
  2: "Ожидает начала",
  3: "Ожидает начала",
  4: "Ожидает начала",
  5: "Трансфер авто",
  6: "Выполняется",
  7: "Услуга оказана",
  8: "Приём услуги",
  9: "Приём авто",
  10: "Парковка авто"
} as const;

export const TEMPLATE = [
  {
    title: 'Как это работает?',
    list: [
      'Вы заказываете нужную услугу',
      'С вами связывается оператор услуги',
      'Согласовывает детали и подтверждает заказ',
      'Отправляет вам ссылку на оплату',
      'Вы оплачиваете',
      'Валет сервис отвозит авто на оказание услуги',
      'Услуга оказывается',
      'Валет сервис возвращает авто на парковку'
    ]
  },
  {
    title: 'Как происходит оплата?',
    text: 'Оплата осуществляется online. После вашего подтверждения, администратор отправит вам ссылку на оплату.'
  },
  {
    title: 'Кто отвозит автомобиль на услугу и забирает с услуги?',
    text: 'Ваш автомобиль отвозит сотрудник Валет-сервиса.'
  },
  {
    title: 'Это безопасно?',
    text: 'Мы фотографируем все этапы',
    list: [
      'Перегон авто на услугу',
      'Приём авто после услуги',
      'Перегон авто на парковку'
    ]
  },
  {
    title: 'Если мне срочно нужен мой автомобиль?',
    text: 'Вам необходимо будет связаться с мойкой или валет-сервисом.'
  },
  {
    title: 'Как мне отменить заказ на услугу?',
    text: 'Вам необходимо будет связаться с мойкой или валет-сервисом.'
  },
  {
    title: 'Кто оказывает услугу?',
    text: 'Услуга мойки:',
    innerList: [
      `<b>Индивидуальный предприниматель Алексеев Владимир Сергеевич (ИП Алексеев В.С.)</b>, зарегистрированный в Едином государственном реестре юридических лиц в качестве индивидуального предпринимателя 05.05.2021 (ОГРНИП 321774600269753, ИНН 772080855937), местонахождение по адресу: г. Москва, ул. Перовская д. 44, к. 2, кв. 11`,
      `<b>Режим работы:</b> 10:00 до 22:00, ежедневно`,
      `<b>Контактный номер телефона:</b> </br><a href="tel:+79854748687">+7&nbsp;985&nbsp;474-86-87`
    ]
  },
  {
    title: 'Хочу пожаловаться на оказание услуги',
    text: 'Все претензии направляются оператору услуги.'
  }
];
