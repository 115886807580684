import {configureStore} from '@reduxjs/toolkit';
import {useDispatch}    from "react-redux";

import parking          from "store/parking/slice";
import service          from "store/service/slice";
import modules          from "store/modules/slice";
import payment          from "store/payment/slice";
import market           from "store/market/slice";

export const store = configureStore({
  reducer: {
    parking,
    service,
    modules,
    payment,
    market
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
