export const IconLogo = () => {
  return (
    <svg width="110" height="20" viewBox="0 0 110 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.764 10.476C11.608 10.476 11.524 10.392 11.524 10.236V9.852C11.524 9.696 11.608 9.612 11.764 9.612H15.784C15.94 9.612 16.024 9.696 16.024 9.852V14.04C16.024 14.196 15.94 14.28 15.784 14.28H6.796C5.272 14.28 4 13.272 4 11.676V8.688C4 7.044 5.176 6 6.772 6H15.496C15.652 6 15.736 6.084 15.736 6.24V6.636C15.736 6.792 15.652 6.876 15.496 6.876H10.18C8.632 6.876 8.08 7.848 8.08 8.892V11.4C8.08 12.684 8.92 13.404 10.072 13.404H14.968V10.476H11.764Z" fill="white"/>
      <path d="M29.5033 14.04C29.5033 14.196 29.4193 14.28 29.2633 14.28H18.3433C18.1873 14.28 18.1033 14.196 18.1033 14.04V6.24C18.1033 6.084 18.1873 6 18.3433 6H29.2633C29.4193 6 29.5033 6.084 29.5033 6.24V6.636C29.5033 6.792 29.4193 6.876 29.2633 6.876H22.2433V9.612H28.3873C28.5433 9.612 28.6273 9.696 28.6273 9.852V10.236C28.6273 10.392 28.5433 10.476 28.3873 10.476H22.2433V13.404H29.2633C29.4193 13.404 29.5033 13.488 29.5033 13.644V14.04Z" fill="white"/>
      <path d="M38.7977 14.04C38.7977 14.196 38.7138 14.28 38.5578 14.28H34.9578C34.8018 14.28 34.7177 14.196 34.7177 14.04V6.876H31.0097C30.8537 6.876 30.7697 6.792 30.7697 6.636V6.24C30.7697 6.084 30.8537 6 31.0097 6H42.4577C42.6137 6 42.6978 6.084 42.6978 6.24V6.636C42.6978 6.792 42.6137 6.876 42.4577 6.876H38.7977V14.04Z" fill="white"/>
      <path d="M51.9044 12.072L56.0564 6.156C56.1284 6.06 56.2244 6 56.3444 6H56.9204C57.1124 6 57.1844 6.132 57.0764 6.288L51.5684 14.124C51.4964 14.22 51.4004 14.28 51.2804 14.28H49.4084C49.2884 14.28 49.1924 14.22 49.1204 14.124L43.6244 6.288C43.5164 6.132 43.5884 6 43.7804 6H47.4644C47.5844 6 47.6804 6.06 47.7524 6.156L51.9044 12.072Z" fill="white"/>
      <path d="M67.9229 13.992C68.0309 14.16 67.9709 14.28 67.7789 14.28H64.0949C63.9749 14.28 63.8789 14.232 63.8069 14.124L62.6429 12.42H56.8949L55.7309 14.124C55.6589 14.232 55.5629 14.28 55.4429 14.28H54.8309C54.6389 14.28 54.5789 14.16 54.6869 13.992L60.0509 6.156C60.1229 6.048 60.2189 6 60.3389 6H62.2829C62.4029 6 62.4989 6.048 62.5709 6.156L67.9229 13.992ZM59.7749 8.208L57.5069 11.532H62.0429L59.7749 8.208Z" fill="white"/>
      <path d="M80.808 14.04C80.808 14.196 80.724 14.28 80.568 14.28H69.648C69.492 14.28 69.408 14.196 69.408 14.04V6.24C69.408 6.084 69.492 6 69.648 6H73.26C73.416 6 73.5 6.084 73.5 6.24V13.404H80.568C80.724 13.404 80.808 13.488 80.808 13.644V14.04Z" fill="white"/>
      <path d="M93.7337 14.04C93.7337 14.196 93.6497 14.28 93.4938 14.28H82.5737C82.4177 14.28 82.3337 14.196 82.3337 14.04V6.24C82.3337 6.084 82.4177 6 82.5737 6H93.4938C93.6497 6 93.7337 6.084 93.7337 6.24V6.636C93.7337 6.792 93.6497 6.876 93.4938 6.876H86.4737V9.612H92.6178C92.7738 9.612 92.8577 9.696 92.8577 9.852V10.236C92.8577 10.392 92.7738 10.476 92.6178 10.476H86.4737V13.404H93.4938C93.6497 13.404 93.7337 13.488 93.7337 13.644V14.04Z" fill="white"/>
      <path d="M103.028 14.04C103.028 14.196 102.944 14.28 102.788 14.28H99.1882C99.0322 14.28 98.9482 14.196 98.9482 14.04V6.876H95.2402C95.0842 6.876 95.0002 6.792 95.0002 6.636V6.24C95.0002 6.084 95.0842 6 95.2402 6H106.688C106.844 6 106.928 6.084 106.928 6.24V6.636C106.928 6.792 106.844 6.876 106.688 6.876H103.028V14.04Z" fill="white"/>
    </svg>
  );
};
