import React        from "react";
import {BTN_COLOR}  from "./consts";

import {clsx}       from "clsx";
import styles       from "./styles.module.scss";
import {Loader} from "../Loader";

interface ButtonProps {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  color?: valueof<typeof BTN_COLOR>;
  link?: string;
  target?: "_blank" | "_self";
  disabled?: boolean;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  title,
  children,
  onClick,
  className,
  color = BTN_COLOR.BLUE,
  link,
  target = "_blank",
  disabled,
  loading
}) => {

  if (link) {
    return (
      <a
        className={clsx(
          styles.button, {
            [styles.graySmall]: color === BTN_COLOR.GRAY_SMALL,
            [styles.blue]: color === BTN_COLOR.BLUE,
            [styles.green]: color === BTN_COLOR.GREEN,
            [styles.disabled]: disabled,
            [styles.loading]: loading
          }, className
        )}
        href={link}
        target={target}
        rel="noreferrer"
      >
        {loading && <Loader className={styles.loader} />}
        {title || children}
      </a>
    );
  }

  if (color === BTN_COLOR.GREEN_STICKY) {
    return (
      <div className={styles.bottomSticky}>
        <button
          className={clsx(styles.greenSticky, {[styles.disabled]: disabled, [styles.loading]: loading}, className)}
          onClick={onClick}
        >
          {loading && <Loader className={styles.loader} />}
          {title || children}
        </button>
      </div>
    );
  }

  return (
    <button
      className={clsx(
        styles.button, {
          [styles.graySmall]: color === BTN_COLOR.GRAY_SMALL,
          [styles.blue]: color === BTN_COLOR.BLUE,
          [styles.green]: color === BTN_COLOR.GREEN,
          [styles.disabled]: disabled,
          [styles.loading]: loading
        }, className
      )}
      onClick={onClick}
    >
      {loading && <Loader className={styles.loader} />}
      {title || children}
    </button>
  );
};
