import React, {useCallback} from "react";
import {Icon} from "components/Icon";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {
  addItemCart, deleteGroupCart,
  removeItemCart, setService
} from "store/market/slice";
import {getFormatPrice} from "utils/currency";
import PaymentInfo from "./PaymentInfo";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

import type {RootState} from "store";
import type {CartItem} from "../../../Model/Marketplace";
import {MARKET_SCREEN} from "../../../consts";

function getCardGroups(list: CartItem[], keyGetter: (it: CartItem) => string | number) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map);
}

const PaymentListItem: React.FC<CartItem & {
  add: (id: number, price: number, title: string, categoryId: number, sectionId: number, image?: string) => void;
  remove: (id: number, price: number, title: string, categoryId: number, sectionId: number, image?: string) => void
}> = ({title, price, amount, add, remove, id, categoryId, sectionId, image}) => {
  return (
    <div className={styles.item}>
      <div className={styles.itemImage} style={{backgroundImage: `url(${image})`}} />
      <div className={styles.itemInfo}>
        <div className={clsx(styles.paymentLabel, styles.itemLabel)}><span>{title}</span></div>
        <div className={styles.itemBottom}>
          <div className={styles.itemCounter}>
            <div className={styles.itemCounterBtn} onClick={() => remove(id, price, title, categoryId, sectionId, image)}><Icon.Minus/></div>
            <div className={styles.itemCount}>{amount}</div>
            <div className={styles.itemCounterBtn} onClick={() => add(id, price, title, categoryId, sectionId, image)}><Icon.Plus/></div>
          </div>
          <span className={styles.itemPrice}>{getFormatPrice(price * amount, true)}</span>
        </div>
      </div>
    </div>
  );
};

const PaymentOrder: React.FC<{title?: string; image?: string;}> = ({title, image}) => {
  return (
    <div className={styles.item}>
      <div className={styles.itemImage} style={{backgroundImage: `url(${image})`}} />
      <div className={styles.itemInfo}>
        <div className={styles.itemLabel}>
          <span>{title}</span>
        </div>
        <div className={styles.itemDesc}>Стоимость будет расчитана менеджеромпосле уточнения деталей</div>
      </div>
    </div>
  );
};

const AboutPayment: React.FC<{price: number}> = ({price}) => {
  return (
    <div className={styles.paymentSum}>
      <div className={styles.paymentSumValue}><span className={styles.paymentLabel}>Итого к оплате:</span><span
        className={styles.paymentLabel}>{getFormatPrice(price, true)}</span></div>
      <div className={styles.paymentSumMethods}>
        <div className={styles.sbp} style={{backgroundImage: `url("./pay-sbp.png")`}}/>
        <div className={styles.visa} style={{backgroundImage: `url("./pay-visa.png")`}}/>
        <div className={styles.mc} style={{backgroundImage: `url("./pay-mc.png")`}}/>
        <div className={styles.mir} style={{backgroundImage: `url("./pay-mir.png")`}}/>
      </div>
    </div>
  );
};

const Payment: React.FC = () => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();
  const isService = model.screen === MARKET_SCREEN.PAYMENT_SERVICE;

  const deleteOrder = useCallback(() => {
    dispatch(setService(null));
  }, []);

  const deleteGroup = useCallback((id: number) => {
    dispatch(deleteGroupCart(id));
  }, []);

  const add = useCallback((id: number, price: number, title: string, categoryId: number, sectionId: number, image?: string) => {
    dispatch(addItemCart({id, price, categoryId, sectionId, title, image}));
  }, []);

  const remove = useCallback((id: number, price: number, title: string, categoryId: number, sectionId: number, image?: string) => {
    dispatch(removeItemCart({id, price, categoryId, sectionId, title, image}));
  }, []);

  let content = null;
  if (isService && model?.service) {
    content = (
      <div className={styles.items}>
        <div className={styles.itemsCategory}>
          <div className={styles.itemsCategoryTitle}>{model?.service?.category}</div>
          <div className={styles.itemsCategoryDelete} onClick={deleteOrder}><Icon.Delete /></div>
        </div>
        {model.service && <PaymentOrder title={model?.service?.title} image={model.service?.image} />}
      </div>
    );
  }
  if (!isService && model.cart?.length) {
    const groups = getCardGroups(model.cart, cart => cart.categoryId);
    const groupsWithLabel: {list: CartItem[]; id: number, label?: string}[] = [];

    groups.forEach(([id, list]) => {
      const label = model?.categories?.find(it => it.id === id)?.title;
      const sorted = list.sort((a: CartItem, b: CartItem) => (a.id - b.id));
      groupsWithLabel.push({id, label, list: sorted});
    });

    content = groupsWithLabel.sort((a, b) => (a.id - b.id)).map((gr, i) => (
      <div className={styles.items} key={i}>
        <div className={styles.itemsCategory}>
          <div className={styles.itemsCategoryTitle}>{gr.label}</div>
          <div className={styles.itemsCategoryDelete} onClick={() => deleteGroup(gr.id)}><Icon.Delete /></div>
        </div>
        {gr.list.map((it, j) => (
          <PaymentListItem key={`${i}-${j}`} {...it} add={add} remove={remove} />
        ))}
      </div>
    ));
  }

  return (
    <div className={styles.payment}>
      {content}
      {!isService && <AboutPayment price={model.totalPrice} />}
      <PaymentInfo />
    </div>
  );
};

export default Payment;
