export const getFullAddress = (city?: string, address?: string) => {
  let addr = '';
  if (city) {
    addr = `${city}`;
  }
  if (address) {
    addr += `, ${address}`;
  }

  return addr;
};
