import {IconArrow}      from './IconArrow';
import {IconCard}       from './IconCard';
import {IconClock}      from './IconClock';
import {IconInfo}       from './IconInfo';
import {IconLogo}       from './IconLogo';
import {IconCarWashing} from './IconCarWashing';
import {IconCarFueling} from './IconCarFueling';
import {IconClose}      from './IconClose';
import {IconBack}       from './IconBack';
import {IconDelete}     from "./IconDelete";
import {IconShop}       from "./IconShop";
import {IconMinus}      from "./IconMinus";
import {IconPlus}       from "./IconPlus";
import {IconWhatsapp}   from "./IconWhatsapp";
import {IconTelegram}   from "./IconTelegram";

export const Icon = {
  Logo: IconLogo,
  Info: IconInfo,
  Clock: IconClock,
  Card: IconCard,
  Arrow: IconArrow,
  CarWashing: IconCarWashing,
  CarFueling: IconCarFueling,
  Close: IconClose,
  Back: IconBack,
  Delete: IconDelete,
  Shop: IconShop,
  Minus: IconMinus,
  Plus: IconPlus,
  Telegram: IconTelegram,
  Whatsapp: IconWhatsapp
};
