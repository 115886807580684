import React, {memo, useEffect} from "react";
import {useSelector} from "react-redux";
import {Icon} from "components/Icon";
import {FULL_DATE_FORMAT, getDateByTime} from "utils/date";
import {SERVICE_STATUSES_TEXT} from "pages/home/consts";

import styles from "./styles.module.scss";

import type {RootState} from "store";

export const Info: React.FC = memo(() => {
  const {parkingInfo} = useSelector((s: RootState) => s.parking);

  const order = parkingInfo?.order_yc?.[0];
  const startedAt = order?.provide_service_at && getDateByTime(order?.provide_service_at).format(FULL_DATE_FORMAT) || null;
  const transferredAt = order?.transferred_at && getDateByTime(order?.transferred_at).format(FULL_DATE_FORMAT) || null;
  const finishedAt = order?.finished_at && getDateByTime(order?.finished_at).format(FULL_DATE_FORMAT) || null;
  const price = order?.price.split('.')?.[0];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.option}>
            <div className={styles.icon}><Icon.Info/></div>
            <dt>Статус заказа</dt>
            <dd>{SERVICE_STATUSES_TEXT[order?.status || 2]}</dd>
          </div>
          <div className={styles.option}>
            <div className={styles.icon}><Icon.Info/></div>
            <dt>Номер заказа</dt>
            <dd>{order?.yc_id}</dd>
          </div>
          {startedAt && <div className={styles.option}>
            <div className={styles.icon}><Icon.Clock/></div>
            <dt>Заказ оформлен</dt>
            <dd>{startedAt}</dd>
          </div>}
          {transferredAt && <div className={styles.option}>
            <div className={styles.icon}><Icon.Clock/></div>
            <dt>Доставлен на мойку</dt>
            <dd>{transferredAt}</dd>
          </div>}
          {finishedAt && <div className={styles.option}>
            <div className={styles.icon}><Icon.Clock/></div>
            <dt>Заказк выполнен</dt>
            <dd>{finishedAt}</dd>
          </div>}
          <div className={styles.option}>
            <div className={styles.icon}><Icon.Info/></div>
            <dt>Стоимость</dt>
            <dd>{price} руб.</dd>
          </div>
        </div>
        {/*<div className={styles.photos}>*/}
        {/*  {order?.photos}*/}
        {/*</div>*/}
    </div>
  );
});
