export const IconTelegram = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12216_2329)">
        <path d="M64 32C64 49.68 49.68 64 32 64C14.32 64 0 49.68 0 32C0 14.32 14.32 0 32 0C49.68 0 64 14.32 64 32Z" fill="#37AEE2"/>
        <path d="M26.1329 46.6667C25.0929 46.6667 25.2795 46.2667 24.9062 45.28L21.8662 35.2533L40.7462 23.4667L42.9595 24.0533L41.1195 29.0667L26.1329 46.6667Z" fill="#C8DAEA"/>
        <path d="M26.1338 46.6666C26.9338 46.6666 27.2805 46.2933 27.7338 45.8666C28.4271 45.2 37.3338 36.5333 37.3338 36.5333L31.8671 35.2L26.8005 38.4L26.1338 46.4V46.6666Z" fill="#A9C9DD"/>
        <path d="M26.6665 38.5066L39.5732 48.0266C41.0398 48.8266 42.1065 48.4266 42.4798 46.6666L47.7332 21.92C48.2665 19.76 46.9065 18.8 45.4932 19.44L14.6665 31.3333C12.5598 32.1866 12.5865 33.36 14.2932 33.8666L22.2132 36.3466L40.5332 24.8C41.3865 24.2666 42.1865 24.56 41.5465 25.1466L26.6665 38.5066Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_12216_2329">
          <rect width="64" height="64" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};
