import React, {useCallback}             from "react";
import {useSelector}                    from "react-redux";
import {useAppDispatch}                 from "store";
import {useSearchParams}                from "react-router-dom";
import {
  addItemCart, removeItemCart,
  setError, setScreen, setService
}                                       from "store/market/slice";
import {Icon}                           from "components/Icon";
import {MARKET_SCREEN}                  from "pages/market/consts";
import {getFormatPrice}                 from "utils/currency";
import {BOTTOM_TYPE}                    from "./consts";

import styles                           from "./styles.module.scss";

import type {RootState}                 from "store";

const Bottom: React.FC<{type: string | null}> = ({type}) => {
  const [params, setParams] = useSearchParams();
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();
  const categoryId = model.categoryId;
  const sectionId = model.categories?.find(cat => cat.id === categoryId)?.section;
  const amount = model.cart.find(it => it.id === model.product?.id)?.amount || 0;

  const clearParams = useCallback(() => {
    if (params.has('category')) {
      params.delete('category');
      setParams(params);
    }
    if (params.has('product')) {
      params.delete('product');
      setParams(params);
    }
  }, [params, setParams]);

  const openPaymentGoods = useCallback(() => {
    clearParams();
    dispatch(setScreen(MARKET_SCREEN.PAYMENT_GOODS));
  }, []);

  const openPaymentService = useCallback(() => {
    clearParams();
    dispatch(setService({title: model.product?.title, category: model.category?.title, id: model.product?.id, image: model.product?.image}));
    dispatch(setScreen(MARKET_SCREEN.PAYMENT_SERVICE));
  }, []);

  const addItem = useCallback(() => {
    if (model.product?.id && model.product?.price) {
      dispatch(addItemCart({id: model.product.id, price: model.product.price, categoryId, sectionId, title: model.product.title, image: model.product.image}));
    }
  }, [model.product, categoryId, sectionId]);

  const removeItem = useCallback(() => {
    if (model.product?.id && model.product?.price) {
      dispatch(removeItemCart({id: model.product.id, price: model.product.price, categoryId, sectionId}));
    }
  }, [model.product, categoryId, sectionId]);

  const returnToCategory = useCallback(() => {
    if (params.has('product')) {
      params.delete('product');
      setParams(params);
    }
    dispatch(setScreen(MARKET_SCREEN.CATEGORY_LIST));
  }, [model.category?.title, model?.product]);

  const goToPayment = useCallback(() => {
    if (!model.fields?.name?.length) {
      dispatch(setError({name: 'name', value: 'Обязательное поле'}));
    }
    if (!model.fields?.phone?.length) {
      dispatch(setError({name: 'phone', value: 'Обязательное поле'}));
    }
    if (!model.agreement) {
      dispatch(setError({name: 'agreement', value: 'Обязательное поле'}));
    }
  }, [model.fields, model.agreement]);

  if (!type) {
    return null;
  }

  let content;
  if (type === BOTTOM_TYPE.PAYMENT_SERVICE) {
    content = (
      <div className={styles.paymentInner} onClick={goToPayment}>
        <span>Оформить заказ</span>
      </div>
    );
  }
  if (type === BOTTOM_TYPE.PAYMENT_PRODUCT) {
    content = (
      <div className={styles.paymentInnerCart} onClick={goToPayment}>
        <span>Перейти к оплате</span><span>{getFormatPrice(model.totalPrice, true)}</span>
      </div>
    );
  }
  if (type === BOTTOM_TYPE.SERVICE) {
    content = (
      <div className={styles.paymentInner} onClick={openPaymentService}>
        <span>Заказать</span>
      </div>
    );
  }
  if (type === BOTTOM_TYPE.PRODUCT) {
    content = (
      <div className={styles.productBottomInner}>
        <div className={styles.counter}>
          <div className={styles.counterBtn} onClick={removeItem}><Icon.Minus /></div>
          <div className={styles.counterValue}>{amount}</div>
          <div className={styles.counterBtn} onClick={addItem}><Icon.Plus /></div>
        </div>
        <div className={styles.productBottomAction} onClick={returnToCategory}>Сохранить</div>
      </div>
    );
  }
  if (type === BOTTOM_TYPE.COMMON_CART) {
    content = (
      <div className={styles.bottomInner}>
        <div className={styles.bottomButtonInfo}>
          <div className={styles.bottomButtonTotal}><span>Сумма заказа:</span><span>{getFormatPrice(model.totalPrice, true)}</span></div>
          <div className={styles.bottomButtonAmount}><span>Товаров в заказе:</span><span>{model.totalAmount}</span></div>
        </div>
        <div className={styles.bottomButtonOrder} onClick={openPaymentGoods}>Оформить</div>
      </div>
    );
  }

  return (
    <div className={styles.bottom}>
      {content}
    </div>
  );
};

export default React.memo(Bottom);

