export const MARKET_SCREEN = {
  MAIN: 'MAIN',
  CATEGORY_LIST: 'CATEGORY_LIST',
  PRODUCT: 'PRODUCT',
  PAYMENT_SERVICE: 'PAYMENT_SERVICE',
  PAYMENT_GOODS: 'PAYMENT_GOODS'
} as const;

export const PRODUCT_TYPE = {
  GOODS: 'goods',
  SERVICES: 'services'
} as const;
