import {memo}   from "react";
import Service  from "pages/service/views";

function ServiceModule() {
  return (
    <Service />
  );
}

export default memo(ServiceModule);
