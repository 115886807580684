export const IconDelete = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.125 4.25H3.54167H14.875" stroke="#011638" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.4587 4.24996V14.1666C13.4587 14.5423 13.3094 14.9027 13.0437 15.1684C12.7781 15.434 12.4177 15.5833 12.042 15.5833H4.95866C4.58294 15.5833 4.2226 15.434 3.95692 15.1684C3.69125 14.9027 3.54199 14.5423 3.54199 14.1666V4.24996M5.66699 4.24996V2.83329C5.66699 2.45757 5.81625 2.09723 6.08192 1.83156C6.3476 1.56588 6.70794 1.41663 7.08366 1.41663H9.91699C10.2927 1.41663 10.6531 1.56588 10.9187 1.83156C11.1844 2.09723 11.3337 2.45757 11.3337 2.83329V4.24996" fill="#011638"/>
        <path d="M7.08301 7.79163V12.0416" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.91699 7.79163V12.0416" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
