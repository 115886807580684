import {createAsyncThunk}         from '@reduxjs/toolkit';
import {getServices, bookRecord, getRecordsByDate} from "services/service";
import {log}                      from "utils/analytics";
import {LOG_ERRORS}               from "utils/consts";

import type {AxiosResponse}       from "axios";
import type {RootState}           from "store";
import type {BookServiceData}     from "services/service";

export const getServiceList = createAsyncThunk(
  'service/list',
  async (_, {rejectWithValue}) => {
    try {
      const res = await getServices();
      return res.data.data;
    } catch (e) {
      const err = e as YCError;
      return rejectWithValue(err.meta.errors[0].code);
    }
  }
);

export const getStaffRecords = createAsyncThunk(
  'service/records',
  async (_, {rejectWithValue, getState}) => {
    const {service: {serviceInfo}, parking: {currentTime}} = getState() as RootState;

    const date = currentTime.format('YYYY-MM-DD');

    try {
      const promises: Promise<AxiosResponse<unknown, unknown>>[] = [];

      serviceInfo.staff.forEach((staff: {id: number}) => {
        promises.push(getRecordsByDate(staff.id, date, serviceInfo.id));
      });

      return  await Promise.all(promises);
    } catch (e) {
      const err = e as YCError;
      return rejectWithValue(err.meta.errors[0].code);
    }
  }
);

export const getStaffRecordsByDate = createAsyncThunk(
  'service/records-by-date',
  async (_, {rejectWithValue, getState}) => {
    const {service: {serviceInfo}, parking: {currentTime}} = getState() as RootState;

    const nextDate = currentTime.add(1, 'day').format('YYYY-MM-DD');

    try {
      const promises: Promise<AxiosResponse<unknown, unknown>>[] = [];

      serviceInfo.staff.forEach((staff: {id: number}) => {
        promises.push(getRecordsByDate(staff.id, nextDate, serviceInfo.id));
      });

      return  await Promise.all(promises);
    } catch (e) {
      const err = e as YCError;
      return rejectWithValue(err.meta.errors[0].code);
    }
  }
);

// async function book(serviceInfo: any, parkingInfo: any, phone: any, name: any) {
//     const promises: Promise<AxiosResponse<unknown, unknown>>[] = [];
//
//     serviceInfo.staff.forEach((staff: {id: number}) => {
//       promises.push(getRecords(staff.id));
//     });
//
//     const res =  await Promise.all(promises);
//     const seances = formatPromiseItems(res as unknown as PromiseItem[], serviceInfo.duration);
//
//     if (!seances?.length) {
//       const currentTime = parkingInfo?.currentTime;
//       const promises: Promise<AxiosResponse<unknown, unknown>>[] = [];
//       const nextDate = currentTime.add(1, 'day').format('YYYY-MM-DD');
//
//       serviceInfo.staff.forEach((staff: {id: number}) => {
//         promises.push(getRecordsByDate(staff.id, nextDate, serviceInfo.id));
//       });
//
//       const res =  await Promise.all(promises);
//
//       return formatPromiseItems(res as unknown as PromiseItem[], serviceInfo.duration);
//     }
//
//
//   const appointment = {
//     id: 1,
//     services: [serviceInfo.id],
//     staff_id: seances[0].staff,
//     custom_fields: {
//       "VCID": parkingInfo?.id,
//       "CarNumber": parkingInfo?.car_number,
//       "CarModel": parkingInfo?.car_number
//     }
//   };
//
//   const data = {
//     phone,
//     fullname: name,
//     email: "d@yclients.com",
//     appointments: [
//       {...appointment, datetime: seances[0].datetime}
//     ]
//   } as unknown as BookServiceData;
//
//     return  await bookRecord(data);
// }

export const bookServiceRecord = createAsyncThunk(
  'service/book',
  async (_, {rejectWithValue, getState}) => {
    const {service, parking} = getState() as RootState;
    const {field, serviceInfo, seances} = service;
    const {parkingInfo} = parking;

    const name = field['name'];
    const phone = field['phone']?.replace(/\D+/g, "");

    if (!seances?.length) {
      log(LOG_ERRORS.CREATE_SERVICE_ORDER, parkingInfo, {ErrorDescription: 'Store содержит пустой массив seances'});
      return rejectWithValue('Store has not seances!');
    }

    try {
      const appointment = {
        id: 1,
        services: [serviceInfo.id],
        staff_id: seances[0].staff,
        custom_fields: {
          "VCID": parkingInfo?.id,
          "CarNumber": parkingInfo?.car_number,
          "CarModel": parkingInfo?.car_model
        }
      };

      const data = {
        phone,
        fullname: name,
        email: "d@yclients.com",
        appointments: [
          {...appointment, datetime: seances[0].datetime}
        ]
      } as unknown as BookServiceData;

      const bookRes = await bookRecord(data);

      // const bookRes = await book(serviceInfo, parkingInfo, phone, name);

      return bookRes.data;
    } catch (e) {
      log(LOG_ERRORS.CREATE_SERVICE_ORDER, parkingInfo, {ErrorDescription: 'Ошибка при выполение запроса'});
      const err = e as YCError;
      return rejectWithValue(err.response.status);
    }
  }
);

