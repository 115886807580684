import {ObjectResponse, ObjectData, TariffResponse, Tariff} from "./Model/Object";
import {Category, CategoryResponse, Marketplace, MarketplaceResponse, ProductResponse, Product} from "./Model/Marketplace";
import {getFullAddress} from "../../utils/address";

export const getFormattedObject = (data: ObjectResponse): ObjectData => {

  const getTariffs = (data: TariffResponse[]): Tariff[] => {
    return data.map(t => ({
      id: t.id,
      title: t.title,
      description: t.description,
      isDefault: t.is_default,
      price: t.price,
      unitName: t.unit_name,
      propertyObject: t.property_object
    }));
  };

  const parkings = data.parkings.map(p => ({
    id: p.id,
    name: p.name,
    city: p.city,
    address: p.address,
    lng: Number(p.longitude),
    lat: Number(p.latitude),
    parkingType: p.parking_type,
    picture: p.picture,
    parkingEquipment: p.parking_equipment,
    tariffs: getTariffs(p.tariffs)
  }));

  return {
    type: data.type,
    name: data.name,
    description: data.description,
    city: data.city,
    address:data.address,
    lng: Number(data.longitude),
    lat: Number(data.latitude),
    mainCover: data?.main_cover,
    fullAddress: getFullAddress(data.city, data.address),
    parkings,
    marketplace: getFormattedMarket(data.market_place)
  };
};

export const getFormattedMarket = (data: MarketplaceResponse): Marketplace => {
  return {
    id: data.id,
    offer: data?.offer,
    conditions: data?.conditions,
    phone: data.phone,
    whatsappLink: data?.whatsapp_link,
    tgLink: data?.tg_link,
    sections: data.sections.map(s => ({
      id: s.id,
      title: s.title,
      categories: s.categories.map(cat => ({
        id: cat.id,
        section: cat.section,
        title: cat.title,
        description: cat.description,
        thumbImage: cat?.thumbnail_image
      }))
    }))
  };
};

export const getFormattedCategory = (data: CategoryResponse): Category => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    coverImage: data.cover_image,
    categoryType: data.category_type,
    products: data.products.map(p => ({
      id: p.id,
      title: p.title,
      description: p.description,
      thumbImage: p?.thumbnail_image,
      productType: p.product_type,
      price: p.selling_price
    }))
  };
};

export const getFormattedProduct = (data: ProductResponse): Product => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    image: data?.cover_image,
    price: data.selling_price,
    productType: data.product_type,
    categories: data.categories
  };
};
