import axios from "axios";
import * as amplitude from '@amplitude/analytics-browser';

import type {ParkingInfo} from "pages/home/Model/ParkingInfo";

export type ParamType<T = string | number | null | undefined | boolean> = { [id: string]: T}

export const APP_VERSION = process.env.REACT_APP_VERSION;
const isDevServer =
  process.env.REACT_APP_MODE === 'staging' ||
  process.env.REACT_APP_MODE === 'staging-dev2' ||
  process.env.REACT_APP_MODE === 'staging-dev3';
const serverType = isDevServer ? 'DEV' : 'PROD';

export const options = {
  appVersion: APP_VERSION,
  instanceName: serverType,
  minIdLength: 1
};

let values: Dictionary<unknown> = {
  AppVersion: APP_VERSION,
  ServerType: serverType,
  SourceType: 'Open'
};

export const log = (type: string, data: ParkingInfo | null, params?: ParamType) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {

    if (data) {
      values = {
        ...values,
        ValetSessionID: data?.id,
        ValetCardID: data?.valet_card_id,
        CarModel: data?.car_model,
        CarNumber: data?.car_number
      };
    }

    amplitude.track(type, {...values, ...params});
  } else if (process.env.NODE_ENV !== 'development') {
    console.error('amplitude track error, window is not defined');
  }
};

export const logEvent = (type: string, data: ParkingInfo | null, params?: ParamType) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
    if (data) {
      values = {
        ValetSessionID: data?.id,
        ValetCardID: data?.valet_card_id,
        CarModel: data?.car_model,
        CarNumber: data?.car_number
      };
    }

    const deviceId = amplitude?.getDeviceId();
    const sessionId = amplitude?.getSessionId();

    return axios.post('https://api.amplitude.com/2/httpapi', {
      api_key: process.env.REACT_APP_AMPLITUDE_KEY,
      events: [
        {
          event_type: type,
          device_id: deviceId,
          session_id: sessionId,
          ...values,
          ...params
        }
      ]
    });
  }
};
