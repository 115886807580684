import React, {useCallback, useEffect} from "react";
import {addItemCart, removeItemCart, setProductId, setScreen} from "store/market/slice";
import {MARKET_SCREEN, PRODUCT_TYPE} from "pages/market/consts";
import {useSearchParams} from "react-router-dom";
// import {Tabs} from "components/Tabs";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {Icon} from "components/Icon";
import EmptyProducts from "./EmptyProducts";
import {getCategoryInfo} from "store/market/thunk";
import {STATUS} from "store/consts";
import {getFormatPrice} from "utils/currency";
import {BASE_URL} from "services";

import styles from "./styles.module.scss";

import type {RootState} from "store";

interface ProductItemProps {
  onClick: (id: number) => void;
  onAdd: (id: number, price: number, title: string, image?: string) => void;
  onRemove: (id: number, price: number, title: string, image?: string) => void;
  id: number;
  title: string;
  thumbImage?: string;
  productType: string;
  amount?: number;
  price?: number;
}

const ProductItem: React.FC<ProductItemProps> = ({onClick, id, title, thumbImage, productType, onAdd, onRemove, amount, price}) => {
  let btn;
  if (productType === PRODUCT_TYPE.SERVICES) {
    btn = <div className={styles.itemBtn} onClick={() => onClick(id)}>Заказать</div>;
  } else if (productType === PRODUCT_TYPE.GOODS && !amount) {
    btn = <div className={styles.itemProductBtn} onClick={() => onAdd(id, price as number, title, thumbImage)}><span><Icon.Shop/></span><div>{price && getFormatPrice(price, true)}</div></div>;
  } else if (productType === PRODUCT_TYPE.GOODS && amount && amount > 0) {
    btn = <div className={styles.itemProductBtnCounter}>
      <span className={styles.btnCount} onClick={() => onRemove(id, price as number, title, thumbImage)}><Icon.Minus/></span>
      <span className={styles.btnAmount}>{getFormatPrice(amount)}</span>
      <span className={styles.btnCount} onClick={() => onAdd(id, price as number, title, thumbImage)}><Icon.Plus/></span></div>;
  }

  return (
    <div className={styles.item}>
      <div className={styles.itemImage} style={{backgroundImage: `url(${BASE_URL}${thumbImage})`}} onClick={() => {onClick(id);}}/>
      <div className={styles.itemInner}>
        <div className={styles.itemLabel} onClick={() => {onClick(id);}}>{title}</div>
        {btn}
      </div>
    </div>
  );
};

// const TABS = [
//   {title: 'Все', value: null},
//   {title: 'Бизнес', value: 1},
//   {title: 'Спорткары', value: 2},
//   {title: 'Электрокары', value: 3},
//   {title: 'Обычные', value: 4}
// ];

const ProductsList: React.FC = () => {
  const [params, setParams] = useSearchParams();
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const onClick = useCallback((id: number) => {
    params.append('product', String(id));
    setParams(params);
    dispatch(setProductId(id));
    dispatch(setScreen(MARKET_SCREEN.PRODUCT));
  }, []);

  const categoryId = model.categoryId;
  const sectionId = model.categories?.find(cat => cat.id === categoryId)?.section;

  const add = useCallback((id: number, price: number, title: string, image?: string) => {
      dispatch(addItemCart({id, price, categoryId, sectionId, title, image}));
  }, [categoryId, sectionId]);

  const remove = useCallback((id: number, price: number, title: string, image?: string) => {
      dispatch(removeItemCart({id, price, categoryId, sectionId, title, image}));
  }, [categoryId, sectionId]);

  useEffect(() => {
    if (model.categoryId) {
      typeof window !== "undefined" && window.scrollTo(0,0);
      dispatch(getCategoryInfo(model.categoryId));
    }
  }, []);

  // const onClickTab = useCallback((id: number) => {
  //   dispatch(setCategoryId(id));
  // }, []);

  return (
    <div className={styles.wrapper}>
      {/*<Tabs tabs={TABS} active={category} onClick={onClickTab} className={styles.tabs} />*/}
      <div className={styles.items}>
        {model.loadingCategory === STATUS.LOADING && <EmptyProducts />}
        {model?.category?.products?.map((it, i: number) => {
          const amount = model.cart.find(p => p.id === it.id)?.amount;
          return (
            <ProductItem key={i} {...it} amount={amount} onAdd={add} onRemove={remove} onClick={onClick} />
          );
        })}
      </div>
      {/*{!!model.totalAmount && <BottomButton amount={model?.totalAmount} total={model?.totalPrice}/>}*/}
    </div>
  );
};

export default ProductsList;
