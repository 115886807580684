import React, {memo}      from "react";
import {Button}           from "components/Button";
import {BTN_COLOR}        from "components/Button/consts";

import styles             from "./index.module.scss";

import type {ServiceItem} from "pages/service/Model/ServiceItem";

interface ServiceCardProps {
  service: ServiceItem;
  handleSetService?: (id: number, title: string, price: number) => void;
  isAvailable?: boolean;
}

export const ServiceCard: React.FC<ServiceCardProps> = memo(({service, handleSetService, isAvailable}) => {
  return (
    <div className={styles.card} data-nonactive={!isAvailable}>
      <div className={styles.image} style={{background: `url(${service.image_group?.images?.basic?.path}) no-repeat center/cover`}} />
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>{service.title}</div>
          <div className={styles.title}>{service.price_min} ₽</div>
        </div>
        <div className={styles.comment}>{service.comment}</div>
        {handleSetService && (
          <Button className={styles.btn} color={BTN_COLOR.GREEN} onClick={() => isAvailable && handleSetService(service.id, service.booking_title, service.price_min)}>
            Выбрать
          </Button>
        )}
      </div>
    </div>
  );
});
