import React, {useCallback} from 'react';
import {useSelector}        from "react-redux";
import Picker               from 'react-mobile-picker';
import {useAppDispatch}     from "store";
import {setSelectedTime}    from "store/parking/slice";
import {HOURS, MINUTES}     from "components/TimePicker/consts";

import type {RootState}     from "store";

import styles               from './styles.module.scss';

export const TimePicker: React.FC = () => {
  const {selectedTime} = useSelector((s: RootState) => s.parking);
  const dispatch = useAppDispatch();

  const value = {hour: String(selectedTime.hour), minute: String(selectedTime.minute)};

  const handleChangeTime = useCallback((val: {hour: string, minute: string}) => {
    dispatch(setSelectedTime({hour: val.hour, minute: val.minute}));
  }, []);

  return (
    <div className={styles.timePicker}>
      <Picker
        value={value}
        onChange={handleChangeTime}
        height={184}
        itemHeight={74}
        wheelMode={'natural'}
      >
        <Picker.Column key="hour" name="hour">
          {HOURS.map(h => (
            <Picker.Item key={h} value={h}>
              {h}
            </Picker.Item>
          ))}
        </Picker.Column>
        <Picker.Column key="minute" name="minute">
          {MINUTES.map(m => (
            <Picker.Item key={m} value={m}>
              {m}
            </Picker.Item>
          ))}
        </Picker.Column>
      </Picker>
    </div>
  );
};
