export const IconShop = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12006_9735)">
        <path d="M5 1.66663L2.5 4.99996V16.6666C2.5 17.1087 2.67559 17.5326 2.98816 17.8451C3.30072 18.1577 3.72464 18.3333 4.16667 18.3333H15.8333C16.2754 18.3333 16.6993 18.1577 17.0118 17.8451C17.3244 17.5326 17.5 17.1087 17.5 16.6666V4.99996L15 1.66663H5Z" fill="white"/>
        <path d="M2.5 5H17.5" stroke="#011638" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.3337 8.33337C13.3337 9.21743 12.9825 10.0653 12.3573 10.6904C11.7322 11.3155 10.8844 11.6667 10.0003 11.6667C9.11627 11.6667 8.26842 11.3155 7.6433 10.6904C7.01818 10.0653 6.66699 9.21743 6.66699 8.33337" stroke="#011638" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_12006_9735">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
