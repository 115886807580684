import React, {useMemo, memo} from 'react';
import {useSelector}          from "react-redux";
import {MEDIA_URL}            from 'services';
import {
  getDurationTime,
  getPrefixDate
}                             from 'utils/date';
import {InfoCard}             from "components/InfoCard";
import {ServiceCard}          from "pages/home/views/components/ServiceCard";
import {ServiceInfo}          from "pages/home/views/components/ServiceInfo";
import {Button}               from "components/Button";
import {BTN_COLOR}            from "components/Button/consts";
import {DeliveryInfo}         from "components/DeliveryInfo";
import {PaymentNotify}        from "components/PaymentNotify";
// import {AboutValetService}    from "components/AboutValetService";

import type {RootState}       from "store";

export const Info = memo(() => {
  const {
    parkingInfo,
    currentTime,
    startedTime,
    showServiceOrder,
    showProgress,
    deliveryTime,
    state,
    hasDebt
  } = useSelector((s: RootState) => s.parking);

  const duration = useMemo(() => startedTime ? getDurationTime(startedTime, currentTime, true) : null, [startedTime, currentTime]);

  const number = parkingInfo?.car_number || '-';
  const model = parkingInfo?.car_model || '-';
  const image = MEDIA_URL + (parkingInfo?.photos?.length && parkingInfo.photos[0]?.img || '');
  const place = parkingInfo?.parking_place || '-';
  // const price = (parkingInfo?.debt || '0.00') + ' ₽';
  const tariff = parkingInfo?.payment_data?.tariff?.title;
  const startTime = getPrefixDate(startedTime, currentTime) || '-';
  const valetCard = parkingInfo?.valet_card_id || '-';
  const debt = parkingInfo?.payment_data?.session_cost?.split('.')[0]?.replace('-', '');
  const paid = parkingInfo?.payment_data?.paid?.split('.')[0];
  const paidSum = paid !== '0' ? paid : undefined;

  return (
    <>
      {hasDebt && !showProgress && <PaymentNotify debt={debt} subscription={parkingInfo?.subscription} />}
      {showProgress && <DeliveryInfo time={deliveryTime} state={state}/>}
      <InfoCard
        startTime={startTime}
        parkingTime={duration || '-'}
        valetCard={valetCard}
        place={place}
        image={image}
        model={model}
        number={number}
        tariff={tariff}
        paid={paidSum}
      />
      {showServiceOrder && <ServiceInfo />}
      {!showServiceOrder && <ServiceCard info={parkingInfo} />}
      {/*{showProgress && <AboutValetService/>}*/}
      <Button color={BTN_COLOR.BLUE} link="https://t.me/GetValetClientBot">Мне нужна помощь</Button>
    </>
  );
});
