import {useEffect, useRef} from "react";

export default function useInterval(cb: () => void, delay: number | null) {
  const savedCallback = useRef(cb);

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    if (!delay) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
