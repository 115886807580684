import {api} from "services";

export async function getParking({place, id}: {place: string, id: string}) {
  return api.get('valet/session/get/', {
    params: {
      id,
      parking_id: place
    }
  });
}

export async function sendRequest(date: string, cardId: string, parkingId: number, pvsId: number) {
  return api.post('valet/session/book/', {date, id: cardId, parking_id: parkingId, pvs_id: pvsId});
}

export async function checkStatus(pvsId: number) {
  return api.get('valet/session/status/', {
    params: {pvsid: pvsId}
  });
}
