import {createAsyncThunk}             from '@reduxjs/toolkit';
import {getParking, sendRequest, checkStatus}  from "services/parking";
import {SERVER_DATE_FORMAT}           from "utils/consts";
import {DELIVERY_TYPE, TIME_STATUSES} from "pages/submission/consts";
import {getDateByTime}                from "utils/date";

import type {RootState}               from "store";

export const getParkingInfo = createAsyncThunk(
  'parking/info',
  async ({place, id}: {place: string, id: string}, {rejectWithValue}) => {
    try {
      const res = await getParking({place, id});
      return res.data;
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const sendBookRequest = createAsyncThunk(
  'parking/book',
  async (
    {id, pvs, parking}: {id: string | null, pvs?: number, parking?: number},
    {rejectWithValue, getState}) => {
    const model = getState() as RootState;
    const parkingInfo = model.parking.parkingInfo;
    const pvsId = pvs || parkingInfo?.id;
    const parkingId = parking || parkingInfo?.parking?.id;

    let date;

    if (model.parking.deliveryType === DELIVERY_TYPE.NOW) {
      date = getDateByTime()
        .add(10, 'minutes')
        .subtract(3, 'hours')
        .format(SERVER_DATE_FORMAT);
    } else {
      if (model.parking.timeTab === TIME_STATUSES.TOMORROW) {
        date = getDateByTime()
          .set('minutes', model.parking.selectedTime.minute)
          .set('hours', model.parking.selectedTime.hour)
          .add(1, 'days')
          .subtract(3, 'hours')
          .format(SERVER_DATE_FORMAT);
      } else {
        date = getDateByTime()
          .set('minutes', model.parking.selectedTime.minute)
          .set('hours', model.parking.selectedTime.hour)
          .subtract(3, 'hours')
          .format(SERVER_DATE_FORMAT);
      }
    }

    if (!id) {
      return rejectWithValue('Не найден card id');
    }

    if (pvsId !== 0 && !pvsId) {
      return rejectWithValue('Не найден pvs id');
    }

    if (parkingId !== 0 && !parkingId) {
      return rejectWithValue('Не найден parking id');
    }

    try {
      const res = await sendRequest(date, id as string, parkingId as number, pvsId as number);
      return res.data;
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const checkStatusRequest = createAsyncThunk(
  'parking/status',
  async (pvsId: number, {rejectWithValue}) => {
    try {
      const res = await checkStatus(pvsId);
      return {status: res.data?.session_status};
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);
