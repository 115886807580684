import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {Top} from "components/Top";
import {Header} from "../Header";

import styles from "./styles.module.scss";
import {clsx} from "clsx";

interface LayoutProps {
  content?: React.ReactElement | React.ReactElement[];
  children?: React.ReactElement | React.ReactElement[];
  back?: () => void;
  title?: string;
  description?: string;
  image?: string;
  reversed?: boolean;
  hasBottom?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  content,
  children,
  back,
  title,
  description,
  image,
  reversed,
  hasBottom
}) => {
  const location = useLocation();
  const initTop = location.pathname.split('/').length === 2 && !location.search?.length;
  const [isTop, setIsTop] = useState(initTop);

  useEffect(() => {
    if (isTop) {
      setIsTop(false);
    }
  }, []);

  return (
    <>
      <Top image={image || "/bg.jpg"} />
      <div className={clsx(styles.container, {[styles.containerBottom]: hasBottom, [styles.containerTop]: isTop})}>
        <Header reversed={reversed} title={title} text={description} back={back} />
        <div className={styles.content}>
          {content || children}
        </div>
      </div>
    </>
  );
};

export default React.memo(Layout);
