import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import {useSelector}              from "react-redux";
import {useAppDispatch}           from "store";
import {sendBookRequest as sendRequest} from "store/parking/thunk";
import {
  setTimeTab,
  setDeliveryType, updateTimeSubmission
}                                 from "store/parking/slice";
import {setModule}                from "store/modules/slice";
import {BottomSheet}              from "components/BottomSheet";
import {Tabs}                     from "components/Tabs";
import {
  TIME_STATUSES, TABS,
  DELIVERY_TYPE
}                                 from "pages/submission/consts";
import {Button}                   from "components/Button";
import {TimePicker}               from "components/TimePicker";
import {log}                      from "utils/analytics";
import {DATE_FORMAT, LOG_TYPES}   from "utils/consts";
import {differenceInTime, getDateNow} from "utils/date";
import {BTN_COLOR}                from "components/Button/consts";
import getQueryParams             from "utils/getQueryParams";

import styles                     from "./styles.module.scss";

import type {RootState}           from "store";

function Submission() {
  const {id} = useMemo(() => getQueryParams(), []);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const model = useSelector((s: RootState) => s);
  const {
    parkingInfo,
    timeTab,
    deliveryType,
    selectedTime,
    submitting,
    currentTime
  } = model.parking;

  const handleClose = useCallback(() => {
    setIsOpen(false);
    dispatch(setModule(null));
  }, []);

  const handleTimeTab = useCallback((val: valueof<typeof TIME_STATUSES>) => {
    dispatch(setTimeTab(val));
  }, []);

  const handleDeliveryType = useCallback(() => {
    dispatch(setDeliveryType(DELIVERY_TYPE.SELECTED_TIME));
  }, []);

  const sendBookRequest = useCallback(() => {
    if (!submitting) {
        log(LOG_TYPES.BTN_CAR_REQUEST_PRESS_NOW, parkingInfo, {
          CarDeliveryTime: currentTime.format(DATE_FORMAT)
        });
      dispatch(sendRequest({id}));
      dispatch(setModule(null));
    }
  }, [id, submitting, parkingInfo]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      dispatch(updateTimeSubmission(true));

      return () => {
        dispatch(updateTimeSubmission(false));
      };
    }
  }, [isOpen]);

  // Добавляем 10 мин к текущему времени, тк минмальное время подачи 10 мин. Сравниваем с временем подачи
  const differance = differenceInTime(getDateNow().add(10, "minutes"), getDateNow().set("hours", selectedTime.hour).set("minutes", selectedTime.minute));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [_, __, ___, hours, minutes] = differance;
  const isNotValid = timeTab === TIME_STATUSES.TODAY && (hours < 0 || minutes < 0);

  let content;
  if (deliveryType === DELIVERY_TYPE.SELECTED_TIME) {
    content = (
      <>
        <Tabs tabs={TABS} active={timeTab} onClick={(val: valueof<typeof TIME_STATUSES>) => handleTimeTab(val)}/>
        <TimePicker />
        <div className={styles.actions} data-notvalid={isNotValid && submitting}>
          <Button color={BTN_COLOR.BLUE} onClick={handleClose}>
            Отмена
          </Button>
          <Button color={BTN_COLOR.GREEN} onClick={() => {!isNotValid && sendBookRequest();}}>
            Готово
          </Button>
        </div>
      </>
    );
  }
  if (deliveryType === DELIVERY_TYPE.NOW) {
    content = (
      <div className={styles.actions} data-notvalid={submitting}>
        <Button color={BTN_COLOR.BLUE} onClick={handleDeliveryType}>
          Ко времени
        </Button>
        <Button color={BTN_COLOR.GREEN} onClick={sendBookRequest}>
          Сейчас
        </Button>
      </div>
    );
  }

  return (
    <BottomSheet
      isOpen={isOpen}
      handleClose={handleClose}
      title="Время подачи"
      description="Минимальное время подачи 10 мин."
    >
      {content}
    </BottomSheet>
  );
}

export default memo(Submission);
