import React 		from 'react';

import {clsx} 	from "clsx";
import  styles 	from './styles.module.scss';

export const Loader = ({className}: {className?: string}) => {
	return (
		<div className={clsx(styles.wrapper, className)}>
			<div className={styles.loader}>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};
