import React, {memo, useCallback, useMemo} from "react";
import {useSelector}                from "react-redux";
import {useAppDispatch}             from "store";
import {setServicePage}             from "store/service/slice";
import {getParkingInfo}             from "store/parking/thunk";
import getQueryParams               from "utils/getQueryParams";
import {Icon}                       from "components/Icon";
import {SERVICE_STATUSES_TEXT}      from "pages/home/consts";
import {getStatusColor}             from "pages/home/helpers";
import {SERVICE_PAGE}               from "pages/service/consts";
import useInterval                  from "utils/hooks/useInterval";
import {getDateByTime}              from "utils/date";
import {Button}                     from "components/Button";
import {setModule}                  from "store/modules/slice";
import MODULES                      from "pages/modules";
import {BTN_COLOR}                  from "components/Button/consts";

import type {RootState}             from "store";

import styles                       from './styles.module.scss';

export const ServiceInfo: React.FC = memo(() => {
  const {parkingInfo, showServiceOrder} = useSelector((s: RootState) => s.parking);
  const {place, id} = useMemo(() => getQueryParams(), []);
  const dispatch = useAppDispatch();

  if (!parkingInfo?.order_yc?.length || !showServiceOrder) {
    return null;
  }

  const info = parkingInfo?.order_yc?.[0];
  const isChecking = (info.status > 0) && (info.status < 10);
  const delay = isChecking ? 30_000 : null;

  const openInfoPage = useCallback(() => {
    dispatch(setModule(MODULES.SERVICE));
    dispatch(setServicePage(SERVICE_PAGE.INFO));
  }, []);

  useInterval(() => {
    if (place && id && isChecking) {
      dispatch(getParkingInfo({place, id}));
    } else {
      return;
    }
  }, delay);

  const formattedPrice = info?.price.split('.')[0];
  const color = getStatusColor(info?.status);
  const readyTime = getDateByTime(info?.provide_service_at).format('HH:mm');

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.status}>
          <div className={styles.label}>Заказканная услуга:</div>
          <div className={styles.statusMessage} style={{backgroundColor: `${color}`}}>{SERVICE_STATUSES_TEXT[info?.status]}</div>
        </div>
        <div className={styles.service}>
          <div className={styles.icon}><Icon.CarWashing /></div>
          <div className={styles.info}>
            <div className={styles.title}>{info?.title}</div>
            <div className={styles.text}>Время оказания услуги: {readyTime}</div>
            <div className={styles.text}>Стоимость: {formattedPrice} руб.</div>
          </div>
        </div>
        <Button color={BTN_COLOR.GRAY_SMALL} onClick={openInfoPage}>Показать детали заказа</Button>
      </div>
    </>
  );
});
