import React, {memo, useCallback} from "react";
import {useAppDispatch}           from "store";
import {Icon}                     from "components/Icon";
import {Button}                   from "components/Button";
import {
  setServiceCategory,
  setServicePage
}                                 from "store/service/slice";
import {setModule}                from "store/modules/slice";
import {SERVICE, SERVICE_PAGE}    from "pages/service/consts";
import MODULES                    from "pages/modules";
import {log}                      from "utils/analytics";
import {LOG_TYPES}                from "utils/consts";
import {BTN_COLOR}                from "components/Button/consts";
import {SERVICE_TYPE}             from "pages/home/consts";

import styles                     from "./styles.module.scss";

import type {ParkingInfo}         from "pages/home/Model/ParkingInfo";

interface ServiceCardProps {
  info: ParkingInfo | null;
}

export const ServiceCard: React.FC<ServiceCardProps> = memo(({info}) => {
  const dispatch = useAppDispatch();

  if (!info?.parking?.services?.length) {
    return null;
  }
  const actualServices = info.parking.services.map(it => it.name);

  const services: React.ReactElement[] = [];
  if (actualServices.includes(SERVICE_TYPE.WASHING)) {
    services.push(
      <div key="washing" className={styles.service} onClick={() => handleOpenService(SERVICE_PAGE.CAR_WASHING)}>
        <Icon.CarWashing />
        <div className={styles.title}>Заказать мойку</div>
      </div>
    );
  }
  if (actualServices.includes(SERVICE_TYPE.FUELING)) {
    services.push(
      <div key="fueling" className={styles.service} onClick={() => handleOpenService(SERVICE_PAGE.GAS_STATION)}>
        <div className={styles.soon}>Скоро</div>
        <Icon.CarFueling />
        <div className={styles.title}>Заказать заправку</div>
      </div>
    );
  }

  // TODO
  if (!services?.length) {
    return null;
  }

  const handleOpenService = useCallback((val: valueof<typeof SERVICE_PAGE>) => {
    dispatch(setModule(MODULES.SERVICE));
    dispatch(setServicePage(val));

    if (val === SERVICE_PAGE.CAR_WASHING) {
      dispatch(setServiceCategory(SERVICE.CAR_WASHING));
    }
    if (val === SERVICE_PAGE.GAS_STATION) {
      dispatch(setServiceCategory(SERVICE.GAS_STATION));
    }

    if (val === SERVICE_PAGE.ABOUT) {
      log(LOG_TYPES.BTN_SERVICE_ABOUT, info);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.services}>
        {services}
      </div>
      <div className={styles.btn}>
        <Button
          color={BTN_COLOR.GRAY_SMALL}
          onClick={() => handleOpenService(SERVICE_PAGE.ABOUT)}
        >
          Что это? Хочу узнать как работает.
        </Button>
      </div>
    </div>
  );
});
