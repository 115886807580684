import React, {
  memo, useCallback
}                             from 'react';
import {useSelector}          from "react-redux";
import {useAppDispatch}       from "store";
import {Bar}                  from 'components/ProgressBar/Bar';
import {Button}               from "components/Button";
import {BTN_COLOR}            from "components/Button/consts";
import MODULES                from "pages/modules";
import {setModule}            from "store/modules/slice";
import {setPaymentPage}       from "store/payment/slice";
import {PAYMENT_PAGE}         from "pages/payment/consts";
import {
  differenceInTime,
  getDateByTime
}                             from "utils/date";
import getPercentFromTimes    from "utils/getPercentFromTimes";

import {RootState}            from "store";

export const ServiceProgress = memo(() => {
  const {parkingInfo, currentTime, hasDebt} = useSelector((s: RootState) => s.parking);
  const dispatch = useAppDispatch();

  const order = parkingInfo?.order_yc?.[0];
  const delay = order?.length && order?.length / 60;
  const startAtTime = order?.provide_service_at ? getDateByTime(order?.provide_service_at) : null;

  const handleOpen = useCallback(() => {
    if (hasDebt) {
      dispatch(setModule(MODULES.PAYMENT));
      dispatch(setPaymentPage(PAYMENT_PAGE.PROCESS));
    } else {
      dispatch(setModule(MODULES.SUBMISSION));
    }
  }, [hasDebt]);

  if (!startAtTime || !delay) {
    return null;
  }

  const endTime = startAtTime.add(delay, 'm');

  const percent = getPercentFromTimes(startAtTime, endTime, currentTime);

  const timeDone = differenceInTime(currentTime.subtract(59, "seconds"), endTime, true) as number[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [_, __, ___, hours, minutes] = timeDone;

  let label;
  if (percent < 100) {
    label = 'До завершения услуги:';
    if (hours > 0) {
      label += ` ${hours} ч`;
    }
    if (minutes > 0) {
      label += ` ${minutes} мин`;
    }
  } else {
    return <Button color={BTN_COLOR.GREEN_STICKY} onClick={handleOpen}>Подать автомобиль</Button>;
  }

  return (
    <Bar
      label={label}
      percent={percent}
    />
  );
});
