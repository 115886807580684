import React, {useEffect} from "react";
import {useAppDispatch}   from "store";
import {useSelector}      from "react-redux";
import {checkPaymentStatus} from "store/payment/thunk";
import {STATUS}           from "store/consts";
import {Top}              from "components/Top";
import {HeaderName}       from "pages/home/views/components/HeaderName";
import {SubscriptionPaymentCard} from "../components/SubscriptionPaymentCard";
import {Button}           from "components/Button";

import {clsx}             from "clsx";
import styles             from "./styles.module.scss";

import type {RootState}   from "store";

interface Props {
  isError?: boolean;
  orderId: string;
}

const LoaderComponent: React.FC<{isError?: boolean}> = ({isError}) => {
  const label = isError ?
    'Оплата не прошла.' :
    'Оплата прошла успешно!';

  return (
    <div className={styles.pageLoader}>
      <div className={styles.block}>
        <div className={clsx(styles.progress, {
          [styles.progressOk]: !isError,
          [styles.progressError]: isError
        })}>
          <div className={clsx(styles.icon, {
            [styles.ok]: !isError,
            [styles.fail]: isError
          })}></div>
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  );
};

export const SubscriptionStatusPage: React.FC<Props> = ({isError, orderId}) => {
  const dispatch = useAppDispatch();
  const model = useSelector((s: RootState) => s.payment);
  const isShowLoader = model.loading === STATUS.LOADING || isError;

  useEffect(() => {
    if (!isError) {
      dispatch(checkPaymentStatus(orderId));
    }
  }, [isError]);

  if (isShowLoader) {
    return <LoaderComponent isError={isError}/>;
  } else if (model.paymentInfo?.error_message?.length) {
    return (
      <div className={styles.errorPage}>
        <div className={styles.errorContent}>
        <div className={styles.errorMessage}>{model.paymentInfo?.error_message}</div>
        <Button onClick={() => window.open("https://t.me/GetValetClientBot", "_self")}>Техническая поддержка</Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page">
        <Top image="/bg.jpg" />
        <div className={styles.container}>
          <HeaderName fullTitle title={model.paymentInfo?.invoice?.description} />
          <div className={styles.inner}>
            <SubscriptionPaymentCard paymentInfo={model.paymentInfo} />
          </div>
        </div>
      </div>
    );
  }
};
