import React, {useMemo, memo, useEffect}  from 'react';
import {useSelector}                      from "react-redux";
import {useAppDispatch}                   from "store";
import {CHECK_STATUS_REQUEST}             from "utils/consts";
import {differenceInTime}                 from "utils/date";
import {checkStatusRequest}               from "store/parking/thunk";
import {Bar}                              from 'components/ProgressBar/Bar';
import getPercentFromTimes                from "utils/getPercentFromTimes";

import type {RootState}                   from "store";

export const DeliveryProgress = memo(() => {
  const {watchStatus, deliveryTime, parkingInfo, startedTime, state, currentTime} = useSelector((s: RootState) => s.parking);
  const dispatch = useAppDispatch();
  const createdAt = parkingInfo?.request?.created_at;

  if (!deliveryTime || !startedTime || !createdAt) {
    return null;
  }

  const percent = getPercentFromTimes(createdAt, deliveryTime.subtract(3, 'hours'), currentTime);

  const timeDone = useMemo(() => {
    return differenceInTime(currentTime.subtract(59, "seconds").add(deliveryTime.utcOffset(), 'm'), deliveryTime);
  }, [currentTime, deliveryTime]) as number[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [_, __, days, hours, minutes] = timeDone;

  const isFullPercents = state === 8 || state === 7;

  useEffect(() => {
    if (watchStatus && parkingInfo?.id) {
      const statusWatchInterval = setInterval(() => {
        dispatch(checkStatusRequest(parkingInfo.id));
      }, CHECK_STATUS_REQUEST);

      return () => {
        clearInterval(statusWatchInterval);
      };
    }
  }, [watchStatus]);

  if (state === 8) {
    return null;
  }

  let label;
  if (state === 7) {
    label = 'Авто ожидает';
  } else if (percent >= 100) {
    label = 'Уже спешим';
  } else {
    label = 'Осталось:';
    if (days > 0) {
      label += ` ${days} д`;
    }
    if (hours > 0) {
      label += ` ${hours} ч`;
    }
    if (minutes > 0) {
      label += ` ${minutes} мин`;
    }
  }

  return (
    <Bar
      label={label}
      percent={isFullPercents ? 100 : percent}
    />
  );
});
